@charset "UTF-8";

/*----------------------------------------
woocommerce-checkout
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-order {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
      padding: 40px 0 0;
      word-break: break-word;

      h2:not([class]) {
        @include fz(16);
        border: 0;
        display: block;
        margin: 0 20px;
        width: 100%;
      }

      &-overview {
        width: 100%;
        padding: 20px 0;
        word-break: break-word;

        li {
          padding: 5px;
        }

        &__order {
          color: $red;
          font-weight: $bold;
        }
      }

      .c-btn {
        width: 100%;
        padding: 40px 0;

        @include is-mobile {
          padding: 0 0 80px;
        }
      }
    }

    &-order-details,
    &-customer-details {
      width: 100%;
    }

    &-order-details {
      text-align: left;
      padding: 40px 0 0;

      @include is-tablet {
        padding: 20px 0 0;
      }
    }

    &-order-details__title {
      text-align: center;
    }

    &-customer-details {
      text-align: left;
      padding: 40px 0 0;

      @include is-tablet {
        padding: 0;
        margin: 0 0 40px;
      }
    }

    &-column__title {
      text-align: center;
    }
  }
}
