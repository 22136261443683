@charset "UTF-8";

/*----------------------------------------
woocommerce-error
----------------------------------------*/
.woocommerce {
  $this: &;

  .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }

  .edit {
    @include fz(14);
    color: $white;
    background: $black;
    display: inline-block;
    padding: 2px 10px;
    border-radius: 5px;
  }

  h2:not([class]) {
    @include fz(24);
    font-weight: $bold;
    padding: 0 0 20px;
    margin: 0 0 20px;
    border-bottom: 1px solid $color-border;

    @include is-mobile {
      @include fz(16);
      padding: 0 0 10px;
    }
  }

  h3:not([class]) {
    @include fz(18);
    font-weight: $bold;
    padding: 0 0 10px;
    margin: 0 0 20px;
    border-bottom: 1px solid $color-border;

    @include is-mobile {
      @include fz(15);
    }
  }

  p:not([class]) {
    @include fz(14);
    width: 100%;
    line-height: 1.75;
    margin: 0 0 20px;
  }

  mark {
    padding: 2px 4px;
    margin: 2px;
  }

  span:not([class]) {
    padding: 20px 0 0;

    em {
      @include fz(12);
    }
  }

  address:not([class]) {
    width: 100%;
    line-height: 1.75;
    margin: 0 0 20px;
  }

  label {
    cursor: pointer;
    font-weight: $bold;
    padding: 10px 20px 10px 0;
    display: block;

    .required {
      color: $red;
      text-decoration: none;
    }
  }

  legend {
    display: block;
    font-weight: $bold;
    padding: 10px 20px 10px 0;
  }

  textarea {
    @include fz(16);
    width: 100%;
    min-height: 80px;
    resize: vertical;
    font-family: $font;
    // background: $white-gray;
    border: 1px solid $color-border-gray;
    padding: 10px;
  }

  select {
    @include fz(16);
    width: 100%;
    font-family: $font;
    // background: $white-gray;
    border: 1px solid $color-border-gray;
    padding: 10px;
  }

  input {
    @include fz(16);
    width: 100%;
    font-family: $font;
    // background: $white-gray;
    border: 1px solid $color-border-gray;
    padding: 10px;

    &[type="checkbox"],
    &[type="radio"] {
      display: none;

      + span,
      + label {
        @include fz(16);
        position: relative;
        display: inline-flex;
        text-align: left;
        align-items: center;
        padding: 0;

        @include is-mobile {
          @include fz(14);
        }

        &::before {
          content: "";
          display: inline-block;
          border: 1px solid $gray;
          background: $white;
          width: 30px;
          height: 30px;
          vertical-align: middle;
          margin: 0 10px 0 0;
          transition: background .3s $ease, border .3s $ease;

          @include is-mobile {
            width: 20px;
            height: 20px;
          }
        }

        &::after {
          content: "";
          display: inline-block;
          width: 7px;
          height: 14px;
          position: absolute;
          top: 50%;
          left: 16px;
          transition: opacity .3s $ease;
          opacity: 0;
          border: solid $white;
          border-width: 0 3px 3px 0;
          transform: translateY(-50%) rotate(45deg);
          transform-origin: top;

          @include is-mobile {
            left: 12px;
          }
        }

        img {
          vertical-align: middle;
        }
      }

      &:checked + span,
      &:checked + label {
        &::before {
          border-color: $red;
          background: $red;
        }

        &::after {
          opacity: 1;
        }
      }

      &[type="radio"] {
        + span,
        + label {
          &::before {
            border-radius: 50%;
          }
        }
      }

      &[type="checkbox"] {
        + span,
        + label {
          &::before {
            border-radius: 1px;
          }
        }
      }
    }
  }
}
