@charset "UTF-8";

/*----------------------------------------
woocommerce-MyAccount
----------------------------------------*/
.woocommerce {
  $this: &;
  display: flex;
  flex-wrap: wrap;
  text-align: justify;
  margin: 0 auto;
  max-width: 1760px;
  padding: 0 80px 100px;
  position: relative;
  z-index: 0;

  @include is-tablet {
    padding: 0 20px 35px;
  }
}
