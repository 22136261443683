@charset "UTF-8";

/*----------------------------------------
u-columns
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-column__title {
      @include fz(18);
      font-weight: $bold;
      padding: 0 0 10px;
      margin: 0 0 20px;
      border-bottom: 1px solid $color-border;
    }
  }
}
