@charset "UTF-8";

/*----------------------------------------
woocommerce-cart-form
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-cart-form {
      &__contents {
        width: 100%;
        display: block;
      }

      thead {
        display: none;
      }

      tbody,
      tr,
      th,
      td {
        display: block;
      }

      tr {
        width: 100%;
        border-top: 1px solid $color-border;
        vertical-align: middle;

        &:not([class]) {
          display: block;
          padding: 30px 20px;

          @include is-mobile {
            padding: 20px 0;
          }
        }
      }

      .tax_label,
      .includes_tax {
        @include fz(12);
        display: inline-block;
        letter-spacing: -.05rem;
      }

      [type="number"] {
        width: 60px;
        padding: 4px;
        text-align: center;
      }

      &__cart-item {
        min-height: 260px;
        padding: 30px 60px 30px 220px;
        position: relative;

        @include is-mobile {
          min-height: 160px;
          padding: 20px 20px 0 110px;
        }

        .product {
          &-remove {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }

          &-thumbnail {
            font-size: 0;
            width: 160px;
            height: 200px;
            position: absolute;
            top: 30px;
            left: 20px;

            @include is-mobile {
              width: 100px;
              height: 120px;
              top: 20px;
              left: 0;
            }
          }

          &-name {
            display: flex;
            flex-wrap: wrap;
          }

          &-name,
          &-price,
          &-quantity,
          &-subtotal {
            @include fz(18);
            @include ls(1);
            padding: 0 10px;
            margin: 0 0 14px;
            width: 100%;
            overflow: hidden;

            @include is-mobile {
              @include fz(14);
              margin: 0 0 10px;
            }

            &::before {
              @include fz(14);
              content: attr(data-title);
              font-weight: $bold;
              margin: 4px 20px 0 0;
              text-transform: uppercase;
              float: left;
              white-space: nowrap;

              @include is-mobile {
                @include fz(12);
                margin: 1px 20px 0 0;
              }
            }
          }
        }

        .variation {
          font-size: 0;
          padding: 5px 10px 0 0;
          text-align: left;

          @include is-mobile {
            padding: 5px 10px 0 0;
          }

          p:not([class]) {
            line-height: 1.5;
            margin: 0;
          }

          dt,
          dd {
            @include fz(14);
            @include ls(.5);
            padding: 0 5px;

            @include is-mobile {
              @include fz(12);
            }
          }

          dt {
            line-height: 1.5;
            float: left;
          }

          dd {
            margin: 0 5px 5px 0;
            overflow: hidden;
          }
        }

        .remove {
          @include fz(30);
          font-weight: $light;
          color: $gray-black;
          padding: 20px;

          @include is-mobile {
            padding: 0;
          }
        }
      }

      .coupon {
        display: flex;
        flex-wrap: wrap;

        label {
          width: 100%;
        }

        input {
          @include fz(16);
          width: calc(100% - 200px);

          @include is-mobile {
            width: 100%;
            margin: 0 0 20px;
          }
        }

        button {
          font-family: $font;
          font-weight: $bold;
          color: $white;
          width: 200px;
          max-width: 100%;
          margin: 0;
          background: $gray;
          padding: 15px 0;
          border: 0;
          box-shadow: none;
          transition: background .5s $ease;
          text-align: center;
          cursor: pointer;

          @include is-mobile {
            // width: 100%;
            margin: 0 auto;
          }

          &:hover {
            background: $primary;
          }
        }
      }

      [name="update_cart"] {
        font-family: $font;
        font-weight: $bold;
        color: $white;
        width: 200px;
        max-width: 100%;
        background: $red;
        padding: 15px 0;
        border: 0;
        box-shadow: none;
        transition: background .5s $ease;
        text-align: center;
        margin: 40px auto;
        display: block;
        cursor: pointer;

        @include is-mobile {
          margin: 20px auto 0;
        }

        &:hover {
          background: $red-black;
        }

        &:disabled {
          opacity: .5;
          cursor: auto;

          &:hover {
            background: $red;
          }
        }
      }
    }
  }
}
