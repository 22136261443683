@charset "UTF-8";

/*----------------------------------------
woocommerce-button
----------------------------------------*/
.woocommerce {
  $this: &;

  // scss-lint:disable IdSelector
  .button#place_order,
  .single_add_to_cart_button,
  .checkout-button,
  .woocommerce-Button,
  .woocommerce-button,
  .woocommerce-order-hold-info-link {
    @include fz(16);
    font-family: $font;
    width: 320px;
    max-width: 90%;
    padding: 20px 0;
    margin: 40px auto;
    display: block;
    color: $white;
    background: $gray;
    border: 0;
    box-shadow: none;
    position: relative;
    transition: background .5s $ease;
    text-align: center;
    cursor: pointer;

    @include is-mobile {
      @include fz(14);
      width: 248px;
      padding: 12px 0;
    }

    &:hover {
      background: $primary;
    }

    &:disabled {
      opacity: .5;
      cursor: auto;

      &:hover {
        background: $gray;
      }
    }

    &.pay {
      background: $red;

      &:hover {
        background: $red-black;
      }
    }
  }

  // scss-lint:disable IdSelector
  .button#place_order {
    background: $red;

    &:hover {
      background: $red-black;
    }

    &.disabled {
      opacity: .5;

      &:hover {
        background: $red;
        opacity: .5;
      }
    }
  }

  .woocommerce-form-register__submit,
  .woocommerce-form-login__submit,
  .single_add_to_cart_button,
  .checkout-button {
    width: 100%;
    max-width: 100%;
    margin: 0;
    background: $red;
    padding: 15px 0;

    &.disabled {
      opacity: .5;

      &:hover {
        background: $red;
        opacity: .5;
      }
    }

    &.wc-variation-is-unavailable {
      background: $gray;
      opacity: .5;
      cursor: default;

      &:hover {
        background: $gray;
      }
    }

    &:hover {
      background: $red-black;
    }
  }

  .woocommerce-form-register__submit,
  .woocommerce-form-login__submit {
    margin: 40px auto;
  }
}
