@charset "UTF-8";

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce {
  .woocommerce-grouped-product-list-item {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    background: $white-gray;
    margin: 0 0 30px;

    &__quantity {
      order: 1;
    }

    &__price {
      @include fz(18);
      line-height: 1.2;

      &:not(:empty) {
        padding: 10px 0;
      }

      small {
        @include fz(12);
        display: inline-block;
      }

      del {
        @include fz(18);
        margin: 0 5px 5px 0;
        display: inline-block;

        @include is-mobile {
          @include fz(16);
        }
      }

      ins {
        @include fz(18);
        text-decoration: none;
        display: inline-block;

        @include is-mobile {
          @include fz(16);
        }

        .woocommerce-Price {
          &-amount {
            letter-spacing: 0;
            color: $red;
          }

          &-currencySymbol {
            @include fz(16);
            margin: 0 0 0 2px;
          }
        }
      }

      .woocommerce-Price {
        &-amount {
          color: $black;
        }

        &-currencySymbol {
          @include fz(16);
          margin: 0 0 0 2px;
        }
      }
    }

    td {
      width: 100%;
    }

    label {
      padding: 0;
    }
  }
}
