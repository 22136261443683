
/*----------------------------------------
Extend
----------------------------------------*/

%btn-hover-animation {
  position: relative;

  &::before {
    content: "";
    display: block;
    background: $primary;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    transform: scale(.8);
    transform-origin: center;
    transition: transform .5s $ease, opacity .5s $ease;
  }

  &:hover {
    @include is-laptop {
      &::before {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

%btn-hover-line-animation {
  position: relative;
  transition: color $ease .5s;

  &.is-current {
    color: $white;

    &::after {
      transform: scaleX(1);
      transform-origin: center left;
    }
  }

  &:hover {
    @include is-laptop {
      color: $white;

      &::after {
        transform: scaleX(1);
        transform-origin: center left;
      }
    }
  }

  &::after {
    content: "";
    background: $white;
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: transform $ease .5s;
    transform: scaleX(0);
    transform-origin: center right;
  }
}

%btn-hover-line-animation-red {
  position: relative;
  transition: color $ease .5s;

  &.is-current {
    color: $red;

    &::after {
      transform: scaleX(1);
      transform-origin: center left;
    }
  }

  &:hover {
    @include is-laptop {
      color: $red;

      &::after {
        transform: scaleX(1);
        transform-origin: center left;
      }
    }
  }

  &::after {
    content: "";
    background: $red;
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: transform $ease .5s;
    transform: scaleX(0);
    transform-origin: center right;
  }
}
