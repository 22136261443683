@charset "UTF-8";

/*----------------------------------------
woocommerce-pagination
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-pagination {
      width: 100%;
      text-align: center;

      #{$this}-button {
        @include fz(16);
        font-weight: $medium;
        width: auto;
        display: inline-block;
        padding: 5px 20px;
        margin: 0 10px;
      }
    }
  }
}
