@charset "UTF-8";

/*----------------------------------------
cart-collaterals
----------------------------------------*/
.woocommerce {
  .woocommerce-shipping {
    &-methods {
      li {
        margin: 0 0 10px;
      }
    }

    &-destination {
      @include fz(14);
      margin: 0 0 5px;
    }

    &-calculator {
      padding: 10px 0 0;

      .button {
        border: 0;
        color: $white;
        width: 100%;
        background: $black;
        padding: 4px 10px;
        display: inline-block;
        cursor: pointer;
      }
    }
  }

  .shipping-calculator {
    &-button {
      @include fz(12);
      padding: 4px 10px;
      display: inline-block;
      color: $white;
      background: $black;
      margin: 0 0 10px;
      cursor: pointer;
    }

    &-form {
      @include fz(14);

      .form-row {
        margin: 0 0 10px;
      }

      input {
        @include fz(14);
      }
    }
  }

  .cart {
    $this: &;

    &_totals {
      position: sticky;
      top: 0;
      background: $white-gray;
      padding: 30px;

      @include is-mobile {
        padding: 40px 30px;
      }

      h2:not([class]) {
        @include fz(16);
        margin: 0 0 20px;
        padding: 0 0 10px;
      }

      .shop_table {
        width: 100%;
        margin: 0 0 20px;
        display: block;

        // scss-lint:disable SelectorDepth
        .woocommerce-shipping-totals {
          td {
            @include fz(14);
          }
        }

        tbody,
        tr,
        th,
        td {
          display: block;
        }

        tr {
          margin: 0 0 10px;
        }

        th,
        td {
          text-align: left;
        }

        th {
          font-weight: $bold;
          margin: 0 0 5px;
        }

        td {
          @include fz(18);
        }

        .tax_label,
        .includes_tax {
          @include fz(14);
          display: inline-block;
        }
      }
    }
  }
}
