@charset "UTF-8";

/*----------------------------------------
woocommerce-Price-amount
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-Price-amount {
      @include ls(1);
      // font-family: $font-en;
      color: $red;
      font-weight: $bold;
    }
  }
}
