@charset "UTF-8";

/*----------------------------------------
woocommerce-message
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-message {
      @include fz(14);
      width: 100%;
      color: $white;
      background: $green;
      font-weight: $bold;
      padding: 13px 20px 12px;
      border-radius: 5px;
      margin: 0 0 20px;
      order: -1px;

      a {
        color: $white;
        text-decoration: underline;
      }
    }

    &-Message {
      color: $black;
      background: $white-gray;
      padding: 20px;

      #{$this}-Button {
        @include fz(16);
        color: $red;
        display: inline;
        padding: 0 10px;
        background: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
