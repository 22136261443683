@charset "UTF-8";

/*----------------------------------------
wc-item-meta
----------------------------------------*/
.woocommerce {
  $this: &;

  .wc {
    &-item-meta {
      p:not([class]) {
        display: inline-block;
        width: auto;
        margin: 0;
      }
    }
  }
}
