@charset "UTF-8";

/*----------------------------------------
Variables
----------------------------------------*/

/*

# Color */
$black: #111;
/* $black: #111; */
$white: #fff;
/* $white: #fff; */
$gray: #6c6c6c;
/* $gray: #6c6c6c; */
$gray-black: #999;
/* $gray-black: #999; */
$gray-black2: #525252;
/* $gray-black2: #525252; */

$primary: #968879;
/* $primary: #968879; */
$primary-dark: #6a533a;
/* $primary: #6A533A; */
$primary-white: #fdfdfc;
/* $primary-white: #fdfdfc; */
$primary-light: #aba094;
/* $primary-light: #aba094; */

$primary-font: #83715e;

$primary-font2: #cac3bb;


$gold: #ad9544;
/* $gold: #ad9544; */

$white-gray: #f5f5f5;
/* $white-gray: #f5f5f5; */
$white-gray2: #f8f8f8;
/* $white-gray2: #f9f9f9; */
$white-gray3: #eee;
/* $white-gray3: #eee; */
$red: #b85e66;
/* $red: #b85e66; */
$red-black: #751313;
/* $red-black: #751313; */
$red-white: #d23d3d;
/* $red-white: #d23d3d; */

$orange: #f2bd81;
$blue: #9dcce3;
$blue-light: #b7cfdb;
$blue-light2: #e1ecf2;
$yellow-light: #f7f4ed;

$pink: #eda28f;

$pink1: #dd4688;
$pink2: #d46abf;
$pink3: #cc8ef5;


$green: #4caf50;


$fb: #3b5998;
$line: #00b900;
$twitter: #1da1f2;
$insta: #eb506a;

/*

# Border */
$color-border: #ddd;
/* $color-border: #ddd; */
$color-border-black: #111;
/* $color-border-black: #111; */
$color-border-gray: $primary-light;
/* $color-border-gray: #aba094; */

/*

/*

# Shadow */
$shadow: rgba(#777, .1);
/* $shadow: rgba(#777, .1); */
$shadow-hover: rgba(#777, .3);
/* $shadow-hover: rgba(#777, .3); */

/*

# Hover */
$opacity: .7;
/* $opacity: .7; */

/*

# Font Family */
$font: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
/* $font: 游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif */
$font-en: Ubuntu, "Noto Sans JP", Arial, Helvetica, sans-serif;
/* $font-en: "Noto Sans JP", Arial, Helvetica, sans-serif; */
$font-cn: "Microsoft Yahei", "PingHei";
/* $font-cn: "Microsoft Yahei", "PingHei"; */
$font-kr: "Malgun Gothic", "Yoon Gothic";
/* $font-kr: "Malgun Gothic", "Yoon Gothic"; */

/*

# Font Weight */
$light: 300;
/* $light: 300; */
$regular:400;
/* $regular:400; */
$medium: 500;
/* $medium: 500; */
$bold: 900;
/* $bold: 900; */

/*

# Width */
$outer-width: 1560px;
/* $outer-width: 1560px; */
$content-width: 1080px;
/* $content-width: 1080px; */

/*

# Padding */
$side-padding: 20px;
/* $side-padding: 20px; */
$side-padding-mobile: 20px;
/* $side-padding-mobile: 20px; */

/*

# Easing */
$ease: cubic-bezier(.19, 1, .22, 1);
/* $ease: cubic-bezier(.19, 1, .22, 1); */

/*


*/
