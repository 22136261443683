@charset "UTF-8";

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce {
  $this: &;

  // scss-lint:disable SelectorDepth
  .product {
    .woocommerce-loop-product {
      &__title {
        @include fz(14);
        padding: 10px 0;
      }

      &__link {
        margin: 0 0 10px;

        .price {
          @include fz(18);
          line-height: 1.2;
          margin: 0 0 20px;

          small {
            @include fz(12);
            display: inline-block;
          }

          del {
            @include fz(18);
            margin: 0 5px 5px 0;
            display: inline-block;

            @include is-mobile {
              @include fz(16);
            }
          }

          ins {
            @include fz(18);
            text-decoration: none;
            display: inline-block;

            @include is-mobile {
              @include fz(16);
            }

            .woocommerce-Price {
              &-amount {
                letter-spacing: 0;
                color: $red;
              }

              &-currencySymbol {
                @include fz(16);
                margin: 0 0 0 2px;
              }
            }
          }

          .woocommerce-Price {
            &-amount {
              color: $black;
            }

            &-currencySymbol {
              @include fz(16);
              margin: 0 0 0 2px;
            }
          }
        }
      }
    }

    .product_type_variable {
      @include fz(14);
      color: $white;
      background: $primary;
      text-align: center;
      margin: 10px 0 0;
      padding: 5px 0;
      display: block;
      width: 100%;

      &:hover {
        opacity: .8;
      }
    }

    .add_to_cart_button {
      @include fz(14);
      color: $white;
      background: $red;
      text-align: center;
      padding: 10px 0;
      width: 100%;
      display: block;

      &:hover {
        opacity: .8;
      }

      &.loading {
        pointer-events: none;
        opacity: .8;
      }
    }

    .added_to_cart {
      @include fz(14);
      color: $white;
      background: $primary;
      text-align: center;
      margin: 10px 0 0;
      padding: 5px 0;
      display: block;
      width: 100%;

      &:hover {
        opacity: .8;
      }
    }
  }
}
