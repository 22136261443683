@charset "UTF-8";

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-Address {
      &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 10px;
        margin: 0 0 20px;
        border-bottom: 1px solid $color-border;

        h3:not([class]) {
          padding: 0;
          margin: 0;
          border-bottom: 0;
        }
      }

      address:not([class]) {
        background: $white-gray;
        padding: 20px;
      }
    }

    &-address {
      &-fields {
        .form-row {
          margin: 0 0 20px;
        }

        button {
          @include fz(18);
          font-family: $font;
          font-weight: $bold;
          width: 320px;
          max-width: 90%;
          padding: 20px 0;
          margin: 40px auto;
          display: block;
          color: $white;
          background: $gray;
          border: 0;
          box-shadow: none;
          position: relative;
          transition: background .5s $ease;
          cursor: pointer;

          @include is-mobile {
            width: 248px;
            padding: 12px 0;
          }

          &:hover {
            background: $primary;
          }

          &:disabled {
            opacity: .5;
            cursor: auto;

            &:hover {
              background: $gray;
            }

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}
