@charset "UTF-8";


/*----------------------------------------
Variables
----------------------------------------*/
/*

# Color */
/* $black: #111; */
/* $white: #fff; */
/* $gray: #6c6c6c; */
/* $gray-black: #999; */
/* $gray-black2: #525252; */
/* $primary: #968879; */
/* $primary: #6A533A; */
/* $primary-white: #fdfdfc; */
/* $primary-light: #aba094; */
/* $gold: #ad9544; */
/* $white-gray: #f5f5f5; */
/* $white-gray2: #f9f9f9; */
/* $white-gray3: #eee; */
/* $red: #b85e66; */
/* $red-black: #751313; */
/* $red-white: #d23d3d; */
/*

# Border */
/* $color-border: #ddd; */
/* $color-border-black: #111; */
/* $color-border-gray: #aba094; */
/*

/*

# Shadow */
/* $shadow: rgba(#777, .1); */
/* $shadow-hover: rgba(#777, .3); */
/*

# Hover */
/* $opacity: .7; */
/*

# Font Family */
/* $font: 游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif */
/* $font-en: "Noto Sans JP", Arial, Helvetica, sans-serif; */
/* $font-cn: "Microsoft Yahei", "PingHei"; */
/* $font-kr: "Malgun Gothic", "Yoon Gothic"; */
/*

# Font Weight */
/* $light: 300; */
/* $regular:400; */
/* $medium: 500; */
/* $bold: 900; */
/*

# Width */
/* $outer-width: 1560px; */
/* $content-width: 1080px; */
/*

# Padding */
/* $side-padding: 20px; */
/* $side-padding-mobile: 20px; */
/*

# Easing */
/* $ease: cubic-bezier(.19, 1, .22, 1); */
/*


*/
/*----------------------------------------
Extend
----------------------------------------*/
/*----------------------------------------
Keyframes
----------------------------------------*/
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes activeBtn {
  0%,
  100% {
    color: #b85e66;
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    color: #dd4688;
  }
}

@keyframes activeBtnCircle {
  0% {
    transform: scale(0);
  }
  50% {
    border-color: #dd4688;
    opacity: 1;
  }
  80% {
    border-color: #d46abf;
    border-width: 0;
    transform: scale(1.3);
    opacity: 0;
  }
  100% {
    border-color: #cc8ef5;
    border-width: 0;
    transform: scale(1.1);
    opacity: 0;
  }
}

@keyframes activeBtnIcon {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  25% {
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce .woocommerce-Address-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 10px;
  margin: 0 0 20px;
  border-bottom: 1px solid #ddd;
}

.woocommerce .woocommerce-Address-title h3:not([class]) {
  padding: 0;
  margin: 0;
  border-bottom: 0;
}

.woocommerce .woocommerce-Address address:not([class]) {
  background: #f5f5f5;
  padding: 20px;
}

.woocommerce .woocommerce-address-fields .form-row {
  margin: 0 0 20px;
}

.woocommerce .woocommerce-address-fields button {
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 900;
  width: 320px;
  max-width: 90%;
  padding: 20px 0;
  margin: 40px auto;
  display: block;
  color: #fff;
  background: #6c6c6c;
  border: 0;
  box-shadow: none;
  position: relative;
  transition: background 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-address-fields button {
    width: 248px;
    padding: 12px 0;
  }
}

.woocommerce .woocommerce-address-fields button:hover {
  background: #968879;
}

.woocommerce .woocommerce-address-fields button:disabled {
  opacity: .5;
  cursor: auto;
}

.woocommerce .woocommerce-address-fields button:disabled:hover {
  background: #6c6c6c;
}

.woocommerce .woocommerce-address-fields button:disabled::before {
  display: none;
}

/*----------------------------------------
woocommerce-checkout
----------------------------------------*/
.woocommerce .wc-amazon-checkout-message {
  width: 100%;
}

.woocommerce .wc-amazon-checkout-message #pay_with_amazon {
  text-align: center;
  padding: 0 20px 0 0;
  display: inline-block;
  float: none;
}

@media screen and (max-width: 1024px) {
  .woocommerce .wc-amazon-checkout-message #pay_with_amazon {
    display: block;
    padding: 0;
    margin: 0 0 10px;
  }
}

.woocommerce .wc-amazon-checkout-message .woocommerce-info {
  padding: 20px;
}

.woocommerce #pay_with_amazon {
  text-align: center;
  padding: 30px 0;
}

.woocommerce #amazon_consent_widget {
  height: auto;
}

/*----------------------------------------
woocommerce-checkout
----------------------------------------*/
.woocommerce-shipping-fields__field-wrapper, .woocommerce-billing-fields__field-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.woocommerce-shipping-fields__field-wrapper .form-row, .woocommerce-billing-fields__field-wrapper .form-row {
  width: 100%;
  margin: 0 0 20px;
}

@media screen and (max-width: 767px) {
  .woocommerce-shipping-fields__field-wrapper .form-row, .woocommerce-billing-fields__field-wrapper .form-row {
    margin: 0 0 10px;
  }
}

.woocommerce-shipping-fields__field-wrapper .form-row-first, .woocommerce-shipping-fields__field-wrapper .form-row-last, .woocommerce-billing-fields__field-wrapper .form-row-first, .woocommerce-billing-fields__field-wrapper .form-row-last {
  width: 50%;
}

.woocommerce-shipping-fields__field-wrapper .form-row-first, .woocommerce-billing-fields__field-wrapper .form-row-first {
  padding: 10px 10px 10px 0;
}

.woocommerce-shipping-fields__field-wrapper .form-row-last, .woocommerce-billing-fields__field-wrapper .form-row-last {
  padding: 10px 0 10px 10px;
}

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce-breadcrumb {
  display: none;
}

/*----------------------------------------
woocommerce-button
----------------------------------------*/
.woocommerce .button#place_order,
.woocommerce .single_add_to_cart_button,
.woocommerce .checkout-button,
.woocommerce .woocommerce-Button,
.woocommerce .woocommerce-button,
.woocommerce .woocommerce-order-hold-info-link {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  width: 320px;
  max-width: 90%;
  padding: 20px 0;
  margin: 40px auto;
  display: block;
  color: #fff;
  background: #6c6c6c;
  border: 0;
  box-shadow: none;
  position: relative;
  transition: background 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .woocommerce .button#place_order,
  .woocommerce .single_add_to_cart_button,
  .woocommerce .checkout-button,
  .woocommerce .woocommerce-Button,
  .woocommerce .woocommerce-button,
  .woocommerce .woocommerce-order-hold-info-link {
    font-size: 14px;
    font-size: 1.4rem;
    width: 248px;
    padding: 12px 0;
  }
}

.woocommerce .button#place_order:hover,
.woocommerce .single_add_to_cart_button:hover,
.woocommerce .checkout-button:hover,
.woocommerce .woocommerce-Button:hover,
.woocommerce .woocommerce-button:hover,
.woocommerce .woocommerce-order-hold-info-link:hover {
  background: #968879;
}

.woocommerce .button#place_order:disabled,
.woocommerce .single_add_to_cart_button:disabled,
.woocommerce .checkout-button:disabled,
.woocommerce .woocommerce-Button:disabled,
.woocommerce .woocommerce-button:disabled,
.woocommerce .woocommerce-order-hold-info-link:disabled {
  opacity: .5;
  cursor: auto;
}

.woocommerce .button#place_order:disabled:hover,
.woocommerce .single_add_to_cart_button:disabled:hover,
.woocommerce .checkout-button:disabled:hover,
.woocommerce .woocommerce-Button:disabled:hover,
.woocommerce .woocommerce-button:disabled:hover,
.woocommerce .woocommerce-order-hold-info-link:disabled:hover {
  background: #6c6c6c;
}

.woocommerce .button#place_order.pay,
.woocommerce .single_add_to_cart_button.pay,
.woocommerce .checkout-button.pay,
.woocommerce .woocommerce-Button.pay,
.woocommerce .woocommerce-button.pay,
.woocommerce .woocommerce-order-hold-info-link.pay {
  background: #b85e66;
}

.woocommerce .button#place_order.pay:hover,
.woocommerce .single_add_to_cart_button.pay:hover,
.woocommerce .checkout-button.pay:hover,
.woocommerce .woocommerce-Button.pay:hover,
.woocommerce .woocommerce-button.pay:hover,
.woocommerce .woocommerce-order-hold-info-link.pay:hover {
  background: #751313;
}

.woocommerce .button#place_order {
  background: #b85e66;
}

.woocommerce .button#place_order:hover {
  background: #751313;
}

.woocommerce .button#place_order.disabled {
  opacity: .5;
}

.woocommerce .button#place_order.disabled:hover {
  background: #b85e66;
  opacity: .5;
}

.woocommerce .woocommerce-form-register__submit,
.woocommerce .woocommerce-form-login__submit,
.woocommerce .single_add_to_cart_button,
.woocommerce .checkout-button {
  width: 100%;
  max-width: 100%;
  margin: 0;
  background: #b85e66;
  padding: 15px 0;
}

.woocommerce .woocommerce-form-register__submit.disabled,
.woocommerce .woocommerce-form-login__submit.disabled,
.woocommerce .single_add_to_cart_button.disabled,
.woocommerce .checkout-button.disabled {
  opacity: .5;
}

.woocommerce .woocommerce-form-register__submit.disabled:hover,
.woocommerce .woocommerce-form-login__submit.disabled:hover,
.woocommerce .single_add_to_cart_button.disabled:hover,
.woocommerce .checkout-button.disabled:hover {
  background: #b85e66;
  opacity: .5;
}

.woocommerce .woocommerce-form-register__submit.wc-variation-is-unavailable,
.woocommerce .woocommerce-form-login__submit.wc-variation-is-unavailable,
.woocommerce .single_add_to_cart_button.wc-variation-is-unavailable,
.woocommerce .checkout-button.wc-variation-is-unavailable {
  background: #6c6c6c;
  opacity: .5;
  cursor: default;
}

.woocommerce .woocommerce-form-register__submit.wc-variation-is-unavailable:hover,
.woocommerce .woocommerce-form-login__submit.wc-variation-is-unavailable:hover,
.woocommerce .single_add_to_cart_button.wc-variation-is-unavailable:hover,
.woocommerce .checkout-button.wc-variation-is-unavailable:hover {
  background: #6c6c6c;
}

.woocommerce .woocommerce-form-register__submit:hover,
.woocommerce .woocommerce-form-login__submit:hover,
.woocommerce .single_add_to_cart_button:hover,
.woocommerce .checkout-button:hover {
  background: #751313;
}

.woocommerce .woocommerce-form-register__submit,
.woocommerce .woocommerce-form-login__submit {
  margin: 40px auto;
}

/*----------------------------------------
cart-collaterals
----------------------------------------*/
.woocommerce .woocommerce-shipping-methods li {
  margin: 0 0 10px;
}

.woocommerce .woocommerce-shipping-destination {
  font-size: 14px;
  font-size: 1.4rem;
  margin: 0 0 5px;
}

.woocommerce .woocommerce-shipping-calculator {
  padding: 10px 0 0;
}

.woocommerce .woocommerce-shipping-calculator .button {
  border: 0;
  color: #fff;
  width: 100%;
  background: #111;
  padding: 4px 10px;
  display: inline-block;
  cursor: pointer;
}

.woocommerce .shipping-calculator-button {
  font-size: 12px;
  font-size: 1.2rem;
  padding: 4px 10px;
  display: inline-block;
  color: #fff;
  background: #111;
  margin: 0 0 10px;
  cursor: pointer;
}

.woocommerce .shipping-calculator-form {
  font-size: 14px;
  font-size: 1.4rem;
}

.woocommerce .shipping-calculator-form .form-row {
  margin: 0 0 10px;
}

.woocommerce .shipping-calculator-form input {
  font-size: 14px;
  font-size: 1.4rem;
}

.woocommerce .cart_totals {
  position: sticky;
  top: 0;
  background: #f5f5f5;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .woocommerce .cart_totals {
    padding: 40px 30px;
  }
}

.woocommerce .cart_totals h2:not([class]) {
  font-size: 16px;
  font-size: 1.6rem;
  margin: 0 0 20px;
  padding: 0 0 10px;
}

.woocommerce .cart_totals .shop_table {
  width: 100%;
  margin: 0 0 20px;
  display: block;
}

.woocommerce .cart_totals .shop_table .woocommerce-shipping-totals td {
  font-size: 14px;
  font-size: 1.4rem;
}

.woocommerce .cart_totals .shop_table tbody,
.woocommerce .cart_totals .shop_table tr,
.woocommerce .cart_totals .shop_table th,
.woocommerce .cart_totals .shop_table td {
  display: block;
}

.woocommerce .cart_totals .shop_table tr {
  margin: 0 0 10px;
}

.woocommerce .cart_totals .shop_table th,
.woocommerce .cart_totals .shop_table td {
  text-align: left;
}

.woocommerce .cart_totals .shop_table th {
  font-weight: 900;
  margin: 0 0 5px;
}

.woocommerce .cart_totals .shop_table td {
  font-size: 18px;
  font-size: 1.8rem;
}

.woocommerce .cart_totals .shop_table .tax_label,
.woocommerce .cart_totals .shop_table .includes_tax {
  font-size: 14px;
  font-size: 1.4rem;
  display: inline-block;
}

/*----------------------------------------
woocommerce-cart-form
----------------------------------------*/
.woocommerce .woocommerce-cart-form__contents {
  width: 100%;
  display: block;
}

.woocommerce .woocommerce-cart-form thead {
  display: none;
}

.woocommerce .woocommerce-cart-form tbody,
.woocommerce .woocommerce-cart-form tr,
.woocommerce .woocommerce-cart-form th,
.woocommerce .woocommerce-cart-form td {
  display: block;
}

.woocommerce .woocommerce-cart-form tr {
  width: 100%;
  border-top: 1px solid #ddd;
  vertical-align: middle;
}

.woocommerce .woocommerce-cart-form tr:not([class]) {
  display: block;
  padding: 30px 20px;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-cart-form tr:not([class]) {
    padding: 20px 0;
  }
}

.woocommerce .woocommerce-cart-form .tax_label,
.woocommerce .woocommerce-cart-form .includes_tax {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  letter-spacing: -.05rem;
}

.woocommerce .woocommerce-cart-form [type="number"] {
  width: 60px;
  padding: 4px;
  text-align: center;
}

.woocommerce .woocommerce-cart-form__cart-item {
  min-height: 260px;
  padding: 30px 60px 30px 220px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-cart-form__cart-item {
    min-height: 160px;
    padding: 20px 20px 0 110px;
  }
}

.woocommerce .woocommerce-cart-form__cart-item .product-remove {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.woocommerce .woocommerce-cart-form__cart-item .product-thumbnail {
  font-size: 0;
  width: 160px;
  height: 200px;
  position: absolute;
  top: 30px;
  left: 20px;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-cart-form__cart-item .product-thumbnail {
    width: 100px;
    height: 120px;
    top: 20px;
    left: 0;
  }
}

.woocommerce .woocommerce-cart-form__cart-item .product-name {
  display: flex;
  flex-wrap: wrap;
}

.woocommerce .woocommerce-cart-form__cart-item .product-name, .woocommerce .woocommerce-cart-form__cart-item .product-price, .woocommerce .woocommerce-cart-form__cart-item .product-quantity, .woocommerce .woocommerce-cart-form__cart-item .product-subtotal {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  padding: 0 10px;
  margin: 0 0 14px;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-cart-form__cart-item .product-name, .woocommerce .woocommerce-cart-form__cart-item .product-price, .woocommerce .woocommerce-cart-form__cart-item .product-quantity, .woocommerce .woocommerce-cart-form__cart-item .product-subtotal {
    font-size: 14px;
    font-size: 1.4rem;
    margin: 0 0 10px;
  }
}

.woocommerce .woocommerce-cart-form__cart-item .product-name::before, .woocommerce .woocommerce-cart-form__cart-item .product-price::before, .woocommerce .woocommerce-cart-form__cart-item .product-quantity::before, .woocommerce .woocommerce-cart-form__cart-item .product-subtotal::before {
  font-size: 14px;
  font-size: 1.4rem;
  content: attr(data-title);
  font-weight: 900;
  margin: 4px 20px 0 0;
  text-transform: uppercase;
  float: left;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-cart-form__cart-item .product-name::before, .woocommerce .woocommerce-cart-form__cart-item .product-price::before, .woocommerce .woocommerce-cart-form__cart-item .product-quantity::before, .woocommerce .woocommerce-cart-form__cart-item .product-subtotal::before {
    font-size: 12px;
    font-size: 1.2rem;
    margin: 1px 20px 0 0;
  }
}

.woocommerce .woocommerce-cart-form__cart-item .variation {
  font-size: 0;
  padding: 5px 10px 0 0;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-cart-form__cart-item .variation {
    padding: 5px 10px 0 0;
  }
}

.woocommerce .woocommerce-cart-form__cart-item .variation p:not([class]) {
  line-height: 1.5;
  margin: 0;
}

.woocommerce .woocommerce-cart-form__cart-item .variation dt,
.woocommerce .woocommerce-cart-form__cart-item .variation dd {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  letter-spacing: 0.05rem;
  padding: 0 5px;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-cart-form__cart-item .variation dt,
  .woocommerce .woocommerce-cart-form__cart-item .variation dd {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.woocommerce .woocommerce-cart-form__cart-item .variation dt {
  line-height: 1.5;
  float: left;
}

.woocommerce .woocommerce-cart-form__cart-item .variation dd {
  margin: 0 5px 5px 0;
  overflow: hidden;
}

.woocommerce .woocommerce-cart-form__cart-item .remove {
  font-size: 30px;
  font-size: 3rem;
  font-weight: 300;
  color: #999;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-cart-form__cart-item .remove {
    padding: 0;
  }
}

.woocommerce .woocommerce-cart-form .coupon {
  display: flex;
  flex-wrap: wrap;
}

.woocommerce .woocommerce-cart-form .coupon label {
  width: 100%;
}

.woocommerce .woocommerce-cart-form .coupon input {
  font-size: 16px;
  font-size: 1.6rem;
  width: calc(100% - 200px);
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-cart-form .coupon input {
    width: 100%;
    margin: 0 0 20px;
  }
}

.woocommerce .woocommerce-cart-form .coupon button {
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 900;
  color: #fff;
  width: 200px;
  max-width: 100%;
  margin: 0;
  background: #6c6c6c;
  padding: 15px 0;
  border: 0;
  box-shadow: none;
  transition: background 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-cart-form .coupon button {
    margin: 0 auto;
  }
}

.woocommerce .woocommerce-cart-form .coupon button:hover {
  background: #968879;
}

.woocommerce .woocommerce-cart-form [name="update_cart"] {
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 900;
  color: #fff;
  width: 200px;
  max-width: 100%;
  background: #b85e66;
  padding: 15px 0;
  border: 0;
  box-shadow: none;
  transition: background 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  text-align: center;
  margin: 40px auto;
  display: block;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-cart-form [name="update_cart"] {
    margin: 20px auto 0;
  }
}

.woocommerce .woocommerce-cart-form [name="update_cart"]:hover {
  background: #751313;
}

.woocommerce .woocommerce-cart-form [name="update_cart"]:disabled {
  opacity: .5;
  cursor: auto;
}

.woocommerce .woocommerce-cart-form [name="update_cart"]:disabled:hover {
  background: #b85e66;
}

/*----------------------------------------
woocommerce-cart
----------------------------------------*/
.woocommerce .woocommerce-cart-form {
  width: calc(100% - 380px);
}

@media screen and (max-width: 1024px) {
  .woocommerce .woocommerce-cart-form {
    width: 100%;
    padding: 0 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-cart-form {
    padding: 0 0 40px;
  }
}

.woocommerce .woocommerce-cart-form .woocommerce-notices-wrapper {
  padding: 0;
}

.woocommerce .woocommerce-cart-form .u-column1 {
  padding-left: 0;
}

.woocommerce .woocommerce-cart-form .u-column2 {
  padding-right: 0;
}

.woocommerce .cart-collaterals {
  width: 380px;
  padding: 0 0 0 60px;
}

@media screen and (max-width: 1024px) {
  .woocommerce .cart-collaterals {
    width: 100%;
    padding: 0;
    margin: -20px 0 0;
  }
}

/*----------------------------------------
woocommerce-checkout
----------------------------------------*/
.woocommerce-checkout {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: justify;
  padding: 40px 0 0;
}

@media screen and (max-width: 1024px) {
  .woocommerce-checkout {
    display: block;
    padding: 20px 0 0;
  }
}

.woocommerce-checkout .woocommerce-privacy-policy-text {
  background: #fff;
}

.woocommerce-checkout .wc-amazon-payments-advanced-populated {
  width: calc(100% - 320px);
  padding: 0 60px 0 0;
}

@media screen and (max-width: 1024px) {
  .woocommerce-checkout .wc-amazon-payments-advanced-populated {
    width: 100%;
    padding: 0 0 40px;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce-checkout .wc-amazon-payments-advanced-populated {
    padding: 0;
  }
}

.woocommerce-checkout .wc-amazon-payments-advanced-populated .woocommerce-notices-wrapper {
  padding: 0;
}

.woocommerce-checkout .wc-amazon-payments-advanced-populated .col2-set {
  width: 100%;
  padding: 0;
}

.woocommerce-checkout .wc-amazon-payments-advanced-populated .col2-set h3:not([class]) {
  margin: 0 0 20px;
}

.woocommerce-checkout .col2-set {
  width: calc(100% - 320px);
  padding: 0 60px 0 0;
}

@media screen and (max-width: 1024px) {
  .woocommerce-checkout .col2-set {
    width: 100%;
    padding: 20px 0 40px;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce-checkout .col2-set {
    padding: 20px 0;
  }
}

.woocommerce-checkout .col2-set .woocommerce-notices-wrapper {
  padding: 0;
}

.woocommerce-checkout .col2-set h3:not([class]) {
  margin: 0;
}

.woocommerce-checkout .col2-set .col-1 {
  margin: 0 0 20px;
}

.woocommerce-checkout #order_review_heading {
  display: none;
}

.woocommerce-checkout-review-order {
  width: 320px;
  top: 0;
  background: #f5f5f5;
  padding: 30px;
}

@media screen and (max-width: 1024px) {
  .woocommerce-checkout-review-order {
    width: 100%;
  }
}

.woocommerce-checkout-review-order-table {
  width: 100%;
  margin: 0 0 20px;
  display: block;
}

@media screen and (max-width: 767px) {
  .woocommerce-checkout-review-order-table {
    margin: 0 0 40px;
  }
}

.woocommerce-checkout-review-order-table tr,
.woocommerce-checkout-review-order-table th,
.woocommerce-checkout-review-order-table td {
  display: block;
}

.woocommerce-checkout-review-order-table thead {
  display: none;
}

.woocommerce-checkout-review-order-table tfoot {
  border: solid #ddd;
  border-width: 1px 0;
  padding: 20px 0 0;
  display: block;
}

.woocommerce-checkout-review-order-table tr {
  margin: 0 0 20px;
}

.woocommerce-checkout-review-order-table th,
.woocommerce-checkout-review-order-table td {
  text-align: left;
}

.woocommerce-checkout-review-order-table th {
  font-weight: 900;
}

.woocommerce-checkout-review-order-table td {
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .woocommerce-checkout-review-order-table td {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.woocommerce-checkout-review-order-table .tax_label,
.woocommerce-checkout-review-order-table .includes_tax {
  font-size: 14px;
  font-size: 1.4rem;
  display: inline-block;
}

.woocommerce-checkout-review-order .variation {
  font-size: 0;
  padding: 5px 10px 0 0;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .woocommerce-checkout-review-order .variation {
    padding: 5px 10px 0 0;
  }
}

.woocommerce-checkout-review-order .variation p:not([class]) {
  line-height: 1.5;
  margin: 0;
}

.woocommerce-checkout-review-order .variation dt,
.woocommerce-checkout-review-order .variation dd {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  letter-spacing: 0.05rem;
  padding: 0 5px;
}

@media screen and (max-width: 767px) {
  .woocommerce-checkout-review-order .variation dt,
  .woocommerce-checkout-review-order .variation dd {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.woocommerce-checkout-review-order .variation dt {
  line-height: 1.5;
  float: left;
}

.woocommerce-checkout-review-order .variation dd {
  margin: 0 5px 5px 0;
  overflow: hidden;
}

.woocommerce-checkout-review-order .button#place_order {
  font-size: 16px;
  font-size: 1.6rem;
  width: 100%;
  max-width: 100%;
  padding: 15px 0;
  margin: 20px auto;
}

.woocommerce .woocommerce-additional-fields {
  margin: 0 0 20px;
}

.woocommerce .woocommerce-additional-fields h3:not([class]) {
  margin: 30px 0 10px;
}

.woocommerce .woocommerce-additional-fields p {
  margin: 0 0 20px;
}

/*----------------------------------------
woocommerce-error
----------------------------------------*/
.woocommerce .screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.woocommerce .edit {
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  background: #111;
  display: inline-block;
  padding: 2px 10px;
  border-radius: 5px;
}

.woocommerce h2:not([class]) {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 900;
  padding: 0 0 20px;
  margin: 0 0 20px;
  border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 767px) {
  .woocommerce h2:not([class]) {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 0 0 10px;
  }
}

.woocommerce h3:not([class]) {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 900;
  padding: 0 0 10px;
  margin: 0 0 20px;
  border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 767px) {
  .woocommerce h3:not([class]) {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.woocommerce p:not([class]) {
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
  line-height: 1.75;
  margin: 0 0 20px;
}

.woocommerce mark {
  padding: 2px 4px;
  margin: 2px;
}

.woocommerce span:not([class]) {
  padding: 20px 0 0;
}

.woocommerce span:not([class]) em {
  font-size: 12px;
  font-size: 1.2rem;
}

.woocommerce address:not([class]) {
  width: 100%;
  line-height: 1.75;
  margin: 0 0 20px;
}

.woocommerce label {
  cursor: pointer;
  font-weight: 900;
  padding: 10px 20px 10px 0;
  display: block;
}

.woocommerce label .required {
  color: #b85e66;
  text-decoration: none;
}

.woocommerce legend {
  display: block;
  font-weight: 900;
  padding: 10px 20px 10px 0;
}

.woocommerce textarea {
  font-size: 16px;
  font-size: 1.6rem;
  width: 100%;
  min-height: 80px;
  resize: vertical;
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  border: 1px solid #aba094;
  padding: 10px;
}

.woocommerce select {
  font-size: 16px;
  font-size: 1.6rem;
  width: 100%;
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  border: 1px solid #aba094;
  padding: 10px;
}

.woocommerce input {
  font-size: 16px;
  font-size: 1.6rem;
  width: 100%;
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  border: 1px solid #aba094;
  padding: 10px;
}

.woocommerce input[type="checkbox"], .woocommerce input[type="radio"] {
  display: none;
}

.woocommerce input[type="checkbox"] + span,
.woocommerce input[type="checkbox"] + label, .woocommerce input[type="radio"] + span,
.woocommerce input[type="radio"] + label {
  font-size: 16px;
  font-size: 1.6rem;
  position: relative;
  display: inline-flex;
  text-align: left;
  align-items: center;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .woocommerce input[type="checkbox"] + span,
  .woocommerce input[type="checkbox"] + label, .woocommerce input[type="radio"] + span,
  .woocommerce input[type="radio"] + label {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.woocommerce input[type="checkbox"] + span::before,
.woocommerce input[type="checkbox"] + label::before, .woocommerce input[type="radio"] + span::before,
.woocommerce input[type="radio"] + label::before {
  content: "";
  display: inline-block;
  border: 1px solid #6c6c6c;
  background: #fff;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin: 0 10px 0 0;
  transition: background 0.3s cubic-bezier(0.19, 1, 0.22, 1), border 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (max-width: 767px) {
  .woocommerce input[type="checkbox"] + span::before,
  .woocommerce input[type="checkbox"] + label::before, .woocommerce input[type="radio"] + span::before,
  .woocommerce input[type="radio"] + label::before {
    width: 20px;
    height: 20px;
  }
}

.woocommerce input[type="checkbox"] + span::after,
.woocommerce input[type="checkbox"] + label::after, .woocommerce input[type="radio"] + span::after,
.woocommerce input[type="radio"] + label::after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 16px;
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: translateY(-50%) rotate(45deg);
  transform-origin: top;
}

@media screen and (max-width: 767px) {
  .woocommerce input[type="checkbox"] + span::after,
  .woocommerce input[type="checkbox"] + label::after, .woocommerce input[type="radio"] + span::after,
  .woocommerce input[type="radio"] + label::after {
    left: 12px;
  }
}

.woocommerce input[type="checkbox"] + span img,
.woocommerce input[type="checkbox"] + label img, .woocommerce input[type="radio"] + span img,
.woocommerce input[type="radio"] + label img {
  vertical-align: middle;
}

.woocommerce input[type="checkbox"]:checked + span::before,
.woocommerce input[type="checkbox"]:checked + label::before, .woocommerce input[type="radio"]:checked + span::before,
.woocommerce input[type="radio"]:checked + label::before {
  border-color: #b85e66;
  background: #b85e66;
}

.woocommerce input[type="checkbox"]:checked + span::after,
.woocommerce input[type="checkbox"]:checked + label::after, .woocommerce input[type="radio"]:checked + span::after,
.woocommerce input[type="radio"]:checked + label::after {
  opacity: 1;
}

.woocommerce input[type="checkbox"][type="radio"] + span::before,
.woocommerce input[type="checkbox"][type="radio"] + label::before, .woocommerce input[type="radio"][type="radio"] + span::before,
.woocommerce input[type="radio"][type="radio"] + label::before {
  border-radius: 50%;
}

.woocommerce input[type="checkbox"][type="checkbox"] + span::before,
.woocommerce input[type="checkbox"][type="checkbox"] + label::before, .woocommerce input[type="radio"][type="checkbox"] + span::before,
.woocommerce input[type="radio"][type="checkbox"] + label::before {
  border-radius: 1px;
}

/*----------------------------------------
u-columns
----------------------------------------*/
.woocommerce .woocommerce-column__title {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 900;
  padding: 0 0 10px;
  margin: 0 0 20px;
  border-bottom: 1px solid #ddd;
}

/*----------------------------------------
u-columns
----------------------------------------*/
.woocommerce .u-columns {
  display: flex;
  max-width: 1560px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .woocommerce .u-columns {
    display: block;
  }
}

.woocommerce .u-column1, .woocommerce .u-column2 {
  width: 50%;
  padding: 0 20px;
}

@media screen and (max-width: 1024px) {
  .woocommerce .u-column1, .woocommerce .u-column2 {
    width: 100%;
    padding: 20px 0;
  }
}

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce .woocommerce-order-delivery-info {
  margin: 0 0 40px;
}

.woocommerce .woocommerce-order-delivery-info-ttl {
  font-size: 18px;
  font-size: 1.8rem;
  padding: 0 0 10px;
  border-bottom: 1px solid #ddd;
  font-weight: 900;
  margin: 0 0 20px;
}

.woocommerce .woocommerce-order-delivery-info-txt {
  margin: 10px 0 0;
}

.woocommerce .woocommerce-order-delivery-info-link {
  color: #b85e66;
}

.woocommerce .woocommerce-order-delivery-info-link:hover {
  text-decoration: underline;
}

/*----------------------------------------
woocommerce-error
----------------------------------------*/
.woocommerce .woocommerce-error {
  width: 100%;
  order: -1px;
  margin: 0 0 20px;
}

.woocommerce .woocommerce-error li {
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  background: #b85e66;
  font-weight: 900;
  padding: 13px 20px 12px;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-error li {
    padding: 14px 10px;
  }
}

.woocommerce .woocommerce-error li:not(:last-child) {
  margin: 0 0 10px;
}

.woocommerce .woocommerce-error a {
  color: #fff;
  text-decoration: underline;
}

.woocommerce .woocommerce-error .woocommerce-Price-amount {
  color: #fff;
}

/*----------------------------------------
woocommerce-form
----------------------------------------*/
.woocommerce .woocommerce-form-coupon {
  max-width: 480px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  width: 100%;
  padding: 20px;
}

@media screen and (max-width: 1024px) {
  .woocommerce .woocommerce-form-coupon {
    max-width: 100%;
  }
}

.woocommerce .woocommerce-form-coupon p {
  font-size: 14px;
  font-size: 1.4rem;
  max-width: 480px;
  margin: 0 0 5px;
}

@media screen and (max-width: 1024px) {
  .woocommerce .woocommerce-form-coupon p {
    max-width: 100%;
  }
}

.woocommerce .woocommerce-form-coupon input {
  background: #fff;
}

.woocommerce .woocommerce-form-coupon button {
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 900;
  color: #fff;
  width: 100%;
  background: #b85e66;
  padding: 10px 0;
  border: 0;
  box-shadow: none;
  transition: background 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  text-align: center;
  margin: 20px auto 0;
  display: block;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-form-coupon button {
    margin: 20px auto 0;
  }
}

.woocommerce .woocommerce-form-coupon button:hover {
  background: #751313;
}

.woocommerce .woocommerce-form-coupon button:disabled {
  opacity: .5;
  cursor: auto;
}

.woocommerce .woocommerce-form-coupon button:disabled:hover {
  background: #b85e66;
}

.woocommerce .woocommerce-form-coupon-toggle {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .woocommerce .woocommerce-form-coupon-toggle {
    padding: 20px 0 0;
  }
}

.woocommerce .woocommerce-form-coupon-toggle .woocommerce-info {
  max-width: 480px;
  padding: 10px;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .woocommerce .woocommerce-form-coupon-toggle .woocommerce-info {
    width: 100%;
    max-width: 100%;
  }
}

/*----------------------------------------
form-register
----------------------------------------*/
.woocommerce .woocommerce-form-register .is-account {
  display: none;
}

/*----------------------------------------
woocommerce-form
----------------------------------------*/
.woocommerce .woocommerce-form-row {
  width: 100%;
  text-align: left;
  margin: 0 0 20px;
}

.woocommerce .woocommerce-ResetPassword {
  max-width: 480px;
  margin: 0 auto;
}

.woocommerce .woocommerce-EditAccountForm .woocommerce-form-row {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;
  margin: 0;
  border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-EditAccountForm .woocommerce-form-row {
    padding: 10px 0 20px;
  }
}

.woocommerce .woocommerce-EditAccountForm .woocommerce-form-row--first, .woocommerce .woocommerce-EditAccountForm .woocommerce-form-row--last {
  width: 50%;
}

.woocommerce .woocommerce-EditAccountForm .woocommerce-form-row--first {
  padding: 30px 0 30px 30px;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-EditAccountForm .woocommerce-form-row--first {
    padding: 10px 0 20px 10px;
  }
}

.woocommerce .woocommerce-EditAccountForm .woocommerce-form-row--last {
  padding: 30px 30px 30px 0;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-EditAccountForm .woocommerce-form-row--last {
    padding: 10px 10px 20px 0;
  }
}

.woocommerce .woocommerce-EditAccountForm label {
  width: 30%;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-EditAccountForm label {
    width: 100%;
  }
}

.woocommerce .woocommerce-EditAccountForm label + label {
  width: 70%;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-EditAccountForm label + label {
    width: 100%;
  }
}

.woocommerce .woocommerce-EditAccountForm input {
  width: 70%;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-EditAccountForm input {
    width: 100%;
  }
}

.woocommerce .woocommerce-EditAccountForm input[name="account_display_name"] + span {
  display: none;
}

.woocommerce .woocommerce-EditAccountForm fieldset {
  width: 100%;
  position: relative;
  text-align: left;
  padding: 20px 0 20px 30%;
  border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-EditAccountForm fieldset {
    padding: 20px 0;
  }
}

.woocommerce .woocommerce-EditAccountForm fieldset legend {
  width: 30%;
  padding: 20px 0;
  position: absolute;
  top: 10px;
  left: 0;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-EditAccountForm fieldset legend {
    width: 100%;
    position: static;
    padding: 20px 0 0;
  }
}

.woocommerce .woocommerce-EditAccountForm fieldset .woocommerce-form-row {
  border-bottom: 0;
  padding: 10px 0;
}

.woocommerce .woocommerce-EditAccountForm fieldset label {
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
  padding: 0;
  margin: 0 0 5px;
}

.woocommerce .woocommerce-EditAccountForm fieldset label[for="password_current"], .woocommerce .woocommerce-EditAccountForm fieldset label[for="password_1"] {
  letter-spacing: -0.5px;
  letter-spacing: -0.05rem;
}

.woocommerce .woocommerce-password-hint {
  font-size: 14px;
  font-size: 1.4rem;
  text-align: justify;
  display: block;
}

.woocommerce .woocommerce-password-strength {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  color: #fff;
  background: #111;
  padding: 3px 10px 2px;
  border-radius: 5px;
  margin: 10px 0;
}

.woocommerce .woocommerce-password-strength.short {
  background: #b85e66;
  font-weight: 900;
}

.woocommerce .woocommerce-password-strength.bad {
  background: #f2bd81;
  font-weight: 900;
}

.woocommerce .woocommerce-password-strength.strong {
  background: #4caf50;
  font-weight: 900;
}

.woocommerce .woocommerce-LostPassword {
  text-align: center;
}

.woocommerce .password-input {
  display: block;
  width: 100%;
  position: relative;
}

.woocommerce .password-input input {
  width: 100%;
}

.woocommerce .show-password-input {
  font-size: 0;
  padding: 10px;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translateY(-50%);
}

.woocommerce .show-password-input.display-password::after {
  content: "\f06e";
}

.woocommerce .show-password-input::after {
  font-size: 17px;
  font-size: 1.7rem;
  content: "\f070";
  color: #111;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce .woocommerce-grouped-product-list-item {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  background: #f5f5f5;
  margin: 0 0 30px;
}

.woocommerce .woocommerce-grouped-product-list-item__quantity {
  order: 1;
}

.woocommerce .woocommerce-grouped-product-list-item__price {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.2;
}

.woocommerce .woocommerce-grouped-product-list-item__price:not(:empty) {
  padding: 10px 0;
}

.woocommerce .woocommerce-grouped-product-list-item__price small {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
}

.woocommerce .woocommerce-grouped-product-list-item__price del {
  font-size: 18px;
  font-size: 1.8rem;
  margin: 0 5px 5px 0;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-grouped-product-list-item__price del {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.woocommerce .woocommerce-grouped-product-list-item__price ins {
  font-size: 18px;
  font-size: 1.8rem;
  text-decoration: none;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-grouped-product-list-item__price ins {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.woocommerce .woocommerce-grouped-product-list-item__price ins .woocommerce-Price-amount {
  letter-spacing: 0;
  color: #b85e66;
}

.woocommerce .woocommerce-grouped-product-list-item__price ins .woocommerce-Price-currencySymbol {
  font-size: 16px;
  font-size: 1.6rem;
  margin: 0 0 0 2px;
}

.woocommerce .woocommerce-grouped-product-list-item__price .woocommerce-Price-amount {
  color: #111;
}

.woocommerce .woocommerce-grouped-product-list-item__price .woocommerce-Price-currencySymbol {
  font-size: 16px;
  font-size: 1.6rem;
  margin: 0 0 0 2px;
}

.woocommerce .woocommerce-grouped-product-list-item td {
  width: 100%;
}

.woocommerce .woocommerce-grouped-product-list-item label {
  padding: 0;
}

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce .woocommerce-order-hold-info {
  margin: 0 0 40px;
}

.woocommerce .woocommerce-order-hold-info-ttl {
  font-size: 14px;
  font-size: 1.4rem;
  margin: 0 0 10px;
  padding: 13px 20px 12px;
  border-radius: 5px;
  background: #b85e66;
  font-weight: 900;
  color: #fff;
}

.woocommerce .woocommerce-order-hold-info-txt {
  margin: 10px 0 0;
}

/*----------------------------------------
woocommerce-info
----------------------------------------*/
.woocommerce .woocommerce-info {
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
  background: #f5f5f5;
  border: 1px solid #ddd;
  font-weight: 900;
  padding: 40px 20px;
  margin: 0 auto 20px;
  text-align: center;
  display: block;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-info {
    margin: 0 auto;
  }
}

.woocommerce .return-to-shop {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  width: 320px;
  max-width: 100%;
  padding: 20px 0;
  margin: 40px auto;
  display: block;
  background: #6c6c6c;
  border: 0;
  box-shadow: none;
  position: relative;
  transition: background 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .woocommerce .return-to-shop {
    font-size: 14px;
    font-size: 1.4rem;
    width: 248px;
    padding: 12px 0;
  }
}

.woocommerce .return-to-shop:hover {
  background: #968879;
}

.woocommerce .return-to-shop .button {
  color: #fff;
}

/*----------------------------------------
wc-item-meta
----------------------------------------*/
.woocommerce .wc-item-meta p:not([class]) {
  display: inline-block;
  width: auto;
  margin: 0;
}

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce .product .woocommerce-loop-product__title {
  font-size: 14px;
  font-size: 1.4rem;
  padding: 10px 0;
}

.woocommerce .product .woocommerce-loop-product__link {
  margin: 0 0 10px;
}

.woocommerce .product .woocommerce-loop-product__link .price {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.2;
  margin: 0 0 20px;
}

.woocommerce .product .woocommerce-loop-product__link .price small {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
}

.woocommerce .product .woocommerce-loop-product__link .price del {
  font-size: 18px;
  font-size: 1.8rem;
  margin: 0 5px 5px 0;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .woocommerce .product .woocommerce-loop-product__link .price del {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.woocommerce .product .woocommerce-loop-product__link .price ins {
  font-size: 18px;
  font-size: 1.8rem;
  text-decoration: none;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .woocommerce .product .woocommerce-loop-product__link .price ins {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.woocommerce .product .woocommerce-loop-product__link .price ins .woocommerce-Price-amount {
  letter-spacing: 0;
  color: #b85e66;
}

.woocommerce .product .woocommerce-loop-product__link .price ins .woocommerce-Price-currencySymbol {
  font-size: 16px;
  font-size: 1.6rem;
  margin: 0 0 0 2px;
}

.woocommerce .product .woocommerce-loop-product__link .price .woocommerce-Price-amount {
  color: #111;
}

.woocommerce .product .woocommerce-loop-product__link .price .woocommerce-Price-currencySymbol {
  font-size: 16px;
  font-size: 1.6rem;
  margin: 0 0 0 2px;
}

.woocommerce .product .product_type_variable {
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  background: #968879;
  text-align: center;
  margin: 10px 0 0;
  padding: 5px 0;
  display: block;
  width: 100%;
}

.woocommerce .product .product_type_variable:hover {
  opacity: .8;
}

.woocommerce .product .add_to_cart_button {
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  background: #b85e66;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  display: block;
}

.woocommerce .product .add_to_cart_button:hover {
  opacity: .8;
}

.woocommerce .product .add_to_cart_button.loading {
  pointer-events: none;
  opacity: .8;
}

.woocommerce .product .added_to_cart {
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  background: #968879;
  text-align: center;
  margin: 10px 0 0;
  padding: 5px 0;
  display: block;
  width: 100%;
}

.woocommerce .product .added_to_cart:hover {
  opacity: .8;
}

/*----------------------------------------
woocommerce-message
----------------------------------------*/
.woocommerce .woocommerce-message {
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
  color: #fff;
  background: #4caf50;
  font-weight: 900;
  padding: 13px 20px 12px;
  border-radius: 5px;
  margin: 0 0 20px;
  order: -1px;
}

.woocommerce .woocommerce-message a {
  color: #fff;
  text-decoration: underline;
}

.woocommerce .woocommerce-Message {
  color: #111;
  background: #f5f5f5;
  padding: 20px;
}

.woocommerce .woocommerce-Message .woocommerce-Button {
  font-size: 16px;
  font-size: 1.6rem;
  color: #b85e66;
  display: inline;
  padding: 0 10px;
  background: none;
}

.woocommerce .woocommerce-Message .woocommerce-Button:hover {
  text-decoration: underline;
}

/*----------------------------------------
woocommerce-MyAccount
----------------------------------------*/
.woocommerce .woocommerce-MyAccount-navigation {
  width: 330px;
  padding: 0 40px 0 0;
}

@media screen and (max-width: 1024px) {
  .woocommerce .woocommerce-MyAccount-navigation {
    background: #f5f5f5;
    padding: 20px 40px;
    width: 100%;
    order: 2;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-MyAccount-navigation {
    width: 100%;
    padding: 20px 20px 40px;
  }
}

.woocommerce .woocommerce-MyAccount-navigation-link {
  text-align: left;
  border-bottom: 1px solid #cac3bb;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-MyAccount-navigation-link {
    margin: 0;
  }
}

.woocommerce .woocommerce-MyAccount-navigation-link a {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 900;
  display: block;
  padding: 18px 0;
  text-decoration: none;
  transition: color .2s ease;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-MyAccount-navigation-link a {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 18px;
  }
}

.woocommerce .woocommerce-MyAccount-content {
  width: calc(100% - 330px);
  padding: 0 0 0 40px;
}

@media screen and (max-width: 1024px) {
  .woocommerce .woocommerce-MyAccount-content {
    width: 100%;
    order: 1;
    padding: 0 0 30px;
  }
}

.woocommerce .woocommerce-MyAccount-content .u-column1 {
  padding-left: 0;
}

.woocommerce .woocommerce-MyAccount-content .u-column2 {
  padding-right: 0;
}

/*----------------------------------------
woocommerce-notice
----------------------------------------*/
.woocommerce .woocommerce-notice, .woocommerce .woocommerce-NoticeGroup, .woocommerce .woocommerce-notices-wrapper {
  width: 100%;
  margin: 0 0 20px;
  order: -1px;
}

.woocommerce .woocommerce-notices-wrapper:empty {
  display: none;
}

.woocommerce .woocommerce-notice--success {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 900;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-notice--success {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

/*----------------------------------------
woocommerce-order-again
----------------------------------------*/
.woocommerce .order-again .button {
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 900;
  width: 320px;
  max-width: 90%;
  padding: 20px 0;
  margin: 40px auto;
  display: block;
  color: #fff;
  background: #6c6c6c;
  border: 0;
  box-shadow: none;
  position: relative;
  transition: background 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .woocommerce .order-again .button {
    width: 248px;
    padding: 12px 0;
  }
}

.woocommerce .order-again .button:hover {
  background: #968879;
}

.woocommerce .order-again .button:disabled {
  opacity: .5;
  cursor: auto;
}

.woocommerce .order-again .button:disabled:hover {
  background: #6c6c6c;
}

.woocommerce .order-again .button:disabled::before {
  display: none;
}

/*----------------------------------------
cart-collaterals
----------------------------------------*/
.woocommerce #order_review:not([class]) {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .woocommerce #order_review:not([class]) {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce #order_review:not([class]) {
    padding: 0 20px;
  }
}

.woocommerce #order_review:not([class]) > * {
  width: 50%;
  margin: 0 0 20px;
}

@media screen and (max-width: 1024px) {
  .woocommerce #order_review:not([class]) > * {
    width: 100%;
  }
}

.woocommerce #order_review:not([class]) th,
.woocommerce #order_review:not([class]) td {
  padding: 20px 10px;
  border-bottom: 1px solid #ddd;
}

.woocommerce #order_review:not([class]) th {
  font-weight: 900;
}

.woocommerce #order_review:not([class]) thead th,
.woocommerce #order_review:not([class]) thead td,
.woocommerce #order_review:not([class]) tfoot th,
.woocommerce #order_review:not([class]) tfoot td {
  background: #f5f5f5;
  padding: 10px;
}

.woocommerce #order_review:not([class]) .tax_label,
.woocommerce #order_review:not([class]) .includes_tax {
  font-size: 14px;
  font-size: 1.4rem;
  display: inline-block;
}

.woocommerce #order_review:not([class]) #payment {
  padding: 0 0 0 40px;
}

@media screen and (max-width: 1024px) {
  .woocommerce #order_review:not([class]) #payment {
    padding: 20px 0;
  }
}

.woocommerce #order_review:not([class]) .payment_box {
  padding: 10px 0 0;
  display: block !important;
}

.woocommerce #order_review:not([class]) .payment_box p:not([class]) {
  margin: 0;
}

/*----------------------------------------
woocommerce-OrderUpdate
----------------------------------------*/
.woocommerce .woocommerce-OrderUpdate {
  padding: 20px 20px 1px;
  margin: 0 0 20px;
  border-radius: 10px;
  background: #f5f5f5;
}

.woocommerce .woocommerce-OrderUpdate-meta {
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: 900;
  margin: 0 0 4px;
}

/*----------------------------------------
woocommerce-checkout
----------------------------------------*/
.woocommerce .woocommerce-order {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  padding: 40px 0 0;
  word-break: break-word;
}

.woocommerce .woocommerce-order h2:not([class]) {
  font-size: 16px;
  font-size: 1.6rem;
  border: 0;
  display: block;
  margin: 0 20px;
  width: 100%;
}

.woocommerce .woocommerce-order-overview {
  width: 100%;
  padding: 20px 0;
  word-break: break-word;
}

.woocommerce .woocommerce-order-overview li {
  padding: 5px;
}

.woocommerce .woocommerce-order-overview__order {
  color: #b85e66;
  font-weight: 900;
}

.woocommerce .woocommerce-order .c-btn {
  width: 100%;
  padding: 40px 0;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-order .c-btn {
    padding: 0 0 80px;
  }
}

.woocommerce .woocommerce-order-details, .woocommerce .woocommerce-customer-details {
  width: 100%;
}

.woocommerce .woocommerce-order-details {
  text-align: left;
  padding: 40px 0 0;
}

@media screen and (max-width: 1024px) {
  .woocommerce .woocommerce-order-details {
    padding: 20px 0 0;
  }
}

.woocommerce .woocommerce-order-details__title {
  text-align: center;
}

.woocommerce .woocommerce-customer-details {
  text-align: left;
  padding: 40px 0 0;
}

@media screen and (max-width: 1024px) {
  .woocommerce .woocommerce-customer-details {
    padding: 0;
    margin: 0 0 40px;
  }
}

.woocommerce .woocommerce-column__title {
  text-align: center;
}

/*----------------------------------------
woocommerce-orders
----------------------------------------*/
.woocommerce .woocommerce-order-details__title {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 900;
  padding: 0 0 10px;
  border-bottom: 1px solid #ddd;
}

/*----------------------------------------
woocommerce-pagination
----------------------------------------*/
.woocommerce .woocommerce-pagination {
  width: 100%;
  text-align: center;
}

.woocommerce .woocommerce-pagination .woocommerce-button {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  width: auto;
  display: inline-block;
  padding: 5px 20px;
  margin: 0 10px;
}

/*----------------------------------------
woocommerce-error
----------------------------------------*/
.woocommerce .woocommerce-shipping-methods,
.woocommerce .wc_payment_method {
  margin: 0 0 20px;
}

.woocommerce .woocommerce-shipping-methods input[type="checkbox"] + span::before,
.woocommerce .woocommerce-shipping-methods input[type="checkbox"] + label::before, .woocommerce .woocommerce-shipping-methods input[type="radio"] + span::before,
.woocommerce .woocommerce-shipping-methods input[type="radio"] + label::before,
.woocommerce .wc_payment_method input[type="checkbox"] + span::before,
.woocommerce .wc_payment_method input[type="checkbox"] + label::before,
.woocommerce .wc_payment_method input[type="radio"] + span::before,
.woocommerce .wc_payment_method input[type="radio"] + label::before {
  content: "";
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.woocommerce .woocommerce-shipping-methods input[type="checkbox"] + span::after,
.woocommerce .woocommerce-shipping-methods input[type="checkbox"] + label::after, .woocommerce .woocommerce-shipping-methods input[type="radio"] + span::after,
.woocommerce .woocommerce-shipping-methods input[type="radio"] + label::after,
.woocommerce .wc_payment_method input[type="checkbox"] + span::after,
.woocommerce .wc_payment_method input[type="checkbox"] + label::after,
.woocommerce .wc_payment_method input[type="radio"] + span::after,
.woocommerce .wc_payment_method input[type="radio"] + label::after {
  width: 4px;
  height: 9px;
  top: calc(50% - 1px);
  left: 8px;
  transform-origin: center center;
  transform: translateY(-50%) rotate(45deg);
}

.woocommerce .woocommerce-shipping-methods p:not([class]),
.woocommerce .wc_payment_method p:not([class]) {
  font-size: 14px;
  font-size: 1.4rem;
  margin: 10px 0 0;
}

/*----------------------------------------
woocommerce-Price-amount
----------------------------------------*/
.woocommerce .woocommerce-Price-amount {
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  color: #b85e66;
  font-weight: 900;
}

/*----------------------------------------
woocommerce-error
----------------------------------------*/
.woocommerce-privacy-policy-text {
  font-size: 12px;
  font-size: 1.2rem;
  padding: 12px 12px 1px;
  background: #f5f5f5;
}

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce .cross-sells {
  padding: 20px 20px 0;
  margin: 0 0 30px;
  background: #f5f5f5;
}

.woocommerce .cross-sells h2:not([class]) {
  font-size: 16px;
  font-size: 1.6rem;
  padding: 0;
  border-bottom: 0;
}

.woocommerce .cross-sells .products {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.woocommerce .cross-sells .products .product {
  width: 50%;
  padding: 0 10px;
  margin: 0 0 40px;
}

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce .products.up-sells {
  width: calc(50% - 60px);
  margin: 40px 0 0 30px;
  padding: 30px;
  background: #f5f5f5;
}

@media screen and (max-width: 1024px) {
  .woocommerce .products.up-sells {
    width: 50%;
    margin: 20px 0 40px;
    padding: 30px 30px 0;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce .products.up-sells {
    width: 100%;
    padding: 20px 20px 0;
  }
}

.woocommerce .products.up-sells h2:not([class]) {
  font-size: 18px;
  font-size: 1.8rem;
  padding: 0;
  border-bottom: 0;
}

.woocommerce .products.up-sells .products {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.woocommerce .products.up-sells .products .product {
  width: 50%;
  padding: 0 10px;
  margin: 0 0 40px;
}

/*----------------------------------------
woocommerce-address
----------------------------------------*/
@media screen and (max-width: 1024px) {
  .woocommerce.is-product {
    padding: 40px 20px 0;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce.is-product {
    padding: 1px 3.75% 0;
  }
}

.woocommerce.is-difference .product .price {
  font-size: 22px;
  font-size: 2.2rem;
  background: #f5f5f5;
  padding: 15px 20px;
}

.woocommerce-product .woocommerce-notices-wrapper {
  padding: 0 40px;
  margin: 0 0 40px;
}

.woocommerce .content-area {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .woocommerce .content-area {
    margin-top: 16px;
  }
}

.woocommerce .product {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.woocommerce .product .onsale {
  display: none;
}

.woocommerce .product .summary {
  width: 40%;
  position: sticky;
  top: 0;
}

@media screen and (max-width: 1024px) {
  .woocommerce .product .summary {
    position: relative;
    padding: 0 0 0 30px;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce .product .summary {
    width: 100%;
    padding: 0;
    margin: 0 0 30px;
  }
}

.woocommerce .product .quantity {
  float: left;
}

.woocommerce .product .quantity input {
  font-size: 16px;
  font-size: 1.6rem;
  width: 100px;
  text-align: center;
  padding: 14px 0 14px 16px;
  color: #83715e;
  border-color: #cac3bb;
}

@media screen and (max-width: 767px) {
  .woocommerce .product .quantity input {
    font-size: 14px;
    font-size: 1.4rem;
    width: 80px;
    padding: 10px 0;
  }
}

.woocommerce .product .quantity:not(.hidden) + button {
  width: calc(100% - 100px);
}

@media screen and (max-width: 767px) {
  .woocommerce .product .quantity:not(.hidden) + button {
    width: calc(100% - 80px);
    padding: 11px 0;
  }
}

.woocommerce .product_meta {
  padding: 30px 0 0;
  color: #83715e;
}

.woocommerce .product_meta > span {
  display: block;
  margin: 0 0 10px;
}

.woocommerce .product_title {
  font-size: 18px;
  font-size: 1.8rem;
  margin: 0 0 10px;
  width: calc(100% - 60px);
}

@media screen and (max-width: 767px) {
  .woocommerce .product_title {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.woocommerce .product .woocommerce-variation-price .price {
  font-size: 24px;
  font-size: 2.4rem;
  background: none;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .woocommerce .product .woocommerce-variation-price .price {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.woocommerce .product .price {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.2;
  margin: 0 0 20px;
}

.woocommerce .product .price small {
  font-size: 16px;
  font-size: 1.6rem;
  margin: 0 0 0 5px;
}

.woocommerce .product .price del {
  font-size: 32px;
  font-size: 3.2rem;
  margin: 0 10px 10px 0;
}

@media screen and (max-width: 767px) {
  .woocommerce .product .price del {
    font-size: 28px;
    font-size: 2.8rem;
  }
}

.woocommerce .product .price ins {
  font-size: 32px;
  font-size: 3.2rem;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .woocommerce .product .price ins {
    font-size: 28px;
    font-size: 2.8rem;
  }
}

.woocommerce .product .price ins .woocommerce-Price-amount {
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  color: #b85e66;
}

.woocommerce .product .price ins .woocommerce-Price-currencySymbol {
  font-size: 80%;
  margin: 0 2px;
}

.woocommerce .product .price .woocommerce-Price-amount {
  color: #83715e;
}

.woocommerce .product .price .woocommerce-Price-currencySymbol {
  font-size: 80%;
  margin: 0 2px;
}

.woocommerce .product .variations_form th,
.woocommerce .product .variations_form td {
  padding: 10px 0;
}

.woocommerce .product .variations_form select {
  width: 100%;
  padding: 10px 20px;
  color: #aba094;
  border-color: #cac3bb;
}

.woocommerce .product .variations_form .label {
  width: 100px;
  font-weight: normal;
}

.woocommerce .product .variations_form .variations {
  width: 100%;
}

.woocommerce .product .variations_form .reset_variations {
  font-size: 12px;
  font-size: 1.2rem;
  margin: 10px 0 0;
  padding: 6px 20px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  display: none !important;
}

.woocommerce-product-gallery {
  width: 60%;
  padding: 0 70px;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .woocommerce-product-gallery {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce-product-gallery {
    width: 100%;
    padding: 0;
    margin: 0 0 20px;
  }
}

.woocommerce-product-gallery .c-favorite-btn {
  width: 68px;
  height: 68px;
  top: 30px;
  right: 60px;
}

@media screen and (max-width: 1024px) {
  .woocommerce-product-gallery .c-favorite-btn {
    width: 46px;
    height: 46px;
    top: 10px;
    right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce-product-gallery .c-favorite-btn {
    right: 10px;
  }
}

.woocommerce-product-gallery .c-favorite-btn::before {
  border-width: 34px;
}

@media screen and (max-width: 767px) {
  .woocommerce-product-gallery .c-favorite-btn::before {
    border-width: 23px;
  }
}

.woocommerce-product-gallery .c-favorite-btn::after {
  font-size: 30px;
  font-size: 3rem;
}

@media screen and (max-width: 1024px) {
  .woocommerce-product-gallery .c-favorite-btn::after {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.woocommerce-product-gallery__slide {
  margin: 0 0 20px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .woocommerce-product-gallery__slide {
    padding: 0;
    margin: 0 0 10px;
  }
}

.woocommerce-product-gallery__slide .swiper-slide {
  padding: 120% 0 0;
  position: relative;
}

.woocommerce-product-gallery__slide .swiper-slide div {
  background: no-repeat center #f5f5f5;
  background-size: contain;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.woocommerce-product-gallery__slide-controls {
  position: absolute;
  top: 50%;
  right: -50px;
  left: -50px;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 10;
}

@media screen and (max-width: 1024px) {
  .woocommerce-product-gallery__slide-controls {
    right: 0;
    left: 0;
  }
}

.woocommerce-product-gallery__slide-controls-next, .woocommerce-product-gallery__slide-controls-prev {
  width: 50px;
  height: 50px;
  display: block;
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
  pointer-events: auto;
}

@media screen and (max-width: 1024px) {
  .woocommerce-product-gallery__slide-controls-next, .woocommerce-product-gallery__slide-controls-prev {
    background: rgba(255, 255, 255, 0.5);
    width: 40px;
    height: 80px;
  }
}

.woocommerce-product-gallery__slide-controls-next::after, .woocommerce-product-gallery__slide-controls-prev::after {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  border: solid #aba094;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
}

@media screen and (max-width: 1024px) {
  .woocommerce-product-gallery__slide-controls-next::after, .woocommerce-product-gallery__slide-controls-prev::after {
    width: 18px;
    height: 18px;
    border-width: 1px 1px 0 0;
  }
}

@media screen and (max-width: 1024px) {
  .woocommerce-product-gallery__slide-controls-next {
    padding: 0 10px 0 0;
  }
}

@media screen and (max-width: 1024px) {
  .woocommerce-product-gallery__slide-controls-prev {
    padding: 0 0 0 10px;
  }
}

.woocommerce-product-gallery__slide-controls-prev::after {
  transform: rotate(-135deg);
}

.woocommerce-product-gallery__wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .woocommerce-product-gallery__wrapper {
    padding: 0;
  }
}

.woocommerce-product-gallery__image {
  font-size: 0;
  width: 20%;
  padding: 10px;
  cursor: pointer;
  margin-left: -10px;
}

@media screen and (max-width: 767px) {
  .woocommerce-product-gallery__image {
    width: 25%;
  }
}

.woocommerce-product-gallery__image.is-active a {
  box-shadow: 0 0 0 3px #b85e66;
}

.woocommerce-product-gallery__image a {
  display: block;
  pointer-events: none;
}

.woocommerce-product-gallery__image img {
  width: 100%;
}

.woocommerce-tabs {
  display: none;
}

.woocommerce-variation-add-to-cart {
  margin: 20px 0 0;
}

.woocommerce .single_variation_wrap {
  padding: 10px 0 0;
}

.woocommerce .stock {
  font-weight: 900;
  margin: 5px 0 0;
}

.woocommerce .stock.out-of-stock {
  color: #b85e66;
}

/*----------------------------------------
woocommerce-table
----------------------------------------*/
.woocommerce .woocommerce-orders-table, .woocommerce .woocommerce-table {
  width: 100%;
  margin: 0 0 40px;
}

.woocommerce .woocommerce-orders-table .woocommerce-button, .woocommerce .woocommerce-table .woocommerce-button {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  width: auto;
  padding: 10px;
  margin: 0;
}

.woocommerce .woocommerce-orders-table .woocommerce-button + .woocommerce-button, .woocommerce .woocommerce-table .woocommerce-button + .woocommerce-button {
  margin-top: 10px;
}

.woocommerce .woocommerce-orders-table th,
.woocommerce .woocommerce-orders-table td, .woocommerce .woocommerce-table th,
.woocommerce .woocommerce-table td {
  padding: 20px 10px;
  border-bottom: 1px solid #ddd;
}

.woocommerce .woocommerce-orders-table th, .woocommerce .woocommerce-table th {
  font-weight: 900;
}

.woocommerce .woocommerce-orders-table thead th,
.woocommerce .woocommerce-orders-table thead td,
.woocommerce .woocommerce-orders-table tfoot th,
.woocommerce .woocommerce-orders-table tfoot td, .woocommerce .woocommerce-table thead th,
.woocommerce .woocommerce-table thead td,
.woocommerce .woocommerce-table tfoot th,
.woocommerce .woocommerce-table tfoot td {
  background: #f5f5f5;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-table {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-orders-table {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-orders-table .woocommerce-button {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-orders-table__cell-order-actions::before {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-orders-table tbody,
  .woocommerce .woocommerce-orders-table tr {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-orders-table tr {
    background: #f5f5f5;
    padding: 10px;
    margin: 0 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-orders-table th,
  .woocommerce .woocommerce-orders-table td {
    display: block;
    padding: 10px;
    border-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-orders-table td {
    text-align: right;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-orders-table td::before {
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: 900;
    content: attr(data-title);
    float: left;
    padding-top: 3px;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 767px) {
  .woocommerce .woocommerce-orders-table thead {
    display: none;
  }
}

/*----------------------------------------
woocommerce-MyAccount
----------------------------------------*/
.woocommerce {
  display: flex;
  flex-wrap: wrap;
  text-align: justify;
  margin: 0 auto;
  max-width: 1760px;
  padding: 0 80px 100px;
  position: relative;
  z-index: 0;
}

@media screen and (max-width: 1024px) {
  .woocommerce {
    padding: 0 20px 35px;
  }
}
