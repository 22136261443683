@charset "UTF-8";

/*----------------------------------------
u-columns
----------------------------------------*/
.woocommerce {
  .u-columns {
    display: flex;
    max-width: $outer-width;
    margin: 0 auto;

    @include is-tablet {
      display: block;
    }
  }

  .u-column {
    &1,
    &2 {
      width: 50%;
      padding: 0 20px;

      @include is-tablet {
        width: 100%;
        padding: 20px 0;
      }
    }
  }
}
