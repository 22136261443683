@charset "UTF-8";

/*----------------------------------------
woocommerce-checkout
----------------------------------------*/
.woocommerce {
  $this: &;

  .wc-amazon-checkout-message {
    width: 100%;

    // scss-lint:disable IdSelector
    #pay_with_amazon {
      text-align: center;
      padding: 0 20px 0 0;
      display: inline-block;
      float: none;

      @include is-tablet {
        display: block;
        padding: 0;
        margin: 0 0 10px;
      }
    }

    .woocommerce-info {
      padding: 20px;
    }
  }

  // scss-lint:disable IdSelector
  #pay_with_amazon {
    text-align: center;
    padding: 30px 0;
  }

  #amazon_consent_widget {
    height: auto;
  }
}
