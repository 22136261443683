@charset "UTF-8";

/*----------------------------------------
woocommerce-info
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-info {
      @include fz(14);
      width: 100%;
      background: $white-gray;
      border: 1px solid $color-border;
      font-weight: $bold;
      padding: 40px 20px;
      margin: 0 auto 20px;
      text-align: center;
      display: block;

      @include is-mobile {
        margin: 0 auto;
      }
    }
  }

  .return-to-shop {
    @include fz(16);
    font-family: $font;
    width: 320px;
    max-width: 100%;
    padding: 20px 0;
    margin: 40px auto;
    display: block;
    background: $gray;
    border: 0;
    box-shadow: none;
    position: relative;
    transition: background .5s $ease;
    text-align: center;
    cursor: pointer;

    @include is-mobile {
      @include fz(14);
      width: 248px;
      padding: 12px 0;
    }

    &:hover {
      background: $primary;
    }

    .button {
      color: $white;
    }
  }
}
