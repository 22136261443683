@charset "UTF-8";

/*----------------------------------------
woocommerce-checkout
----------------------------------------*/
.woocommerce {
  $this: &;

  &-shipping-fields__field-wrapper,
  &-billing-fields__field-wrapper {
    display: flex;
    flex-wrap: wrap;

    .form-row {
      width: 100%;
      margin: 0 0 20px;

      @include is-mobile {
        margin: 0 0 10px;
      }

      &-first,
      &-last {
        width: 50%;
      }

      &-first {
        padding: 10px 10px 10px 0;
      }

      &-last {
        padding: 10px 0 10px 10px;
      }
    }
  }
}
