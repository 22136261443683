@charset "UTF-8";

/*----------------------------------------
woocommerce-OrderUpdate
----------------------------------------*/
.woocommerce {
  .woocommerce-OrderUpdate {
    padding: 20px 20px 1px;
    margin: 0 0 20px;
    border-radius: 10px;
    background: $white-gray;

    &-meta {
      @include fz(13);
      font-weight: $bold;
      margin: 0 0 4px;
    }
  }
}
