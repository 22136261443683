@charset "UTF-8";

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-order-hold-info {
      margin: 0 0 40px;

      &-ttl {
        @include fz(14);
        margin: 0 0 10px;
        padding: 13px 20px 12px;
        border-radius: 5px;
        background: $red;
        font-weight: $bold;
        color: $white;
      }

      &-txt {
        margin: 10px 0 0;
      }
    }
  }
}
