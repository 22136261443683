@charset "UTF-8";

/*----------------------------------------
woocommerce-notice
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-notice,
    &-NoticeGroup,
    &-notices-wrapper {
      width: 100%;
      margin: 0 0 20px;
      order: -1px;
    }

    &-notices-wrapper {
      &:empty {
        display: none;

      }
    }

    &-notice {
      &--success {
        @include fz(18);
        font-weight: $bold;

        @include is-mobile {
          @include fz(16);
        }
      }
    }
  }
}
