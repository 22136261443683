@charset "UTF-8";

/*----------------------------------------
woocommerce-cart
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-cart-form {
      width: calc(100% - 380px);

      @include is-tablet {
        width: 100%;
        padding: 0 0 20px;
      }

      @include is-mobile {
        padding: 0 0 40px;
      }

      #{$this}-notices-wrapper {
        padding: 0;
      }

      .u-column1 {
        padding-left: 0;
      }

      .u-column2 {
        padding-right: 0;
      }
    }
  }

  .cart-collaterals {
    width: 380px;
    padding: 0 0 0 60px;

    @include is-tablet {
      width: 100%;
      padding: 0;
      margin: -20px 0 0;
    }
  }
}
