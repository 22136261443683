@charset "UTF-8";

/*----------------------------------------
woocommerce-orders
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-order-details {
      &__title {
        @include fz(18);
        font-weight: $bold;
        padding: 0 0 10px;
        border-bottom: 1px solid $color-border;
      }
    }
  }
}
