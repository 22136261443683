@charset "UTF-8";

/*----------------------------------------
woocommerce-table
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-orders-table,
    &-table {
      width: 100%;
      margin: 0 0 40px;

      #{$this}-button {
        @include fz(16);
        font-weight: $medium;
        width: auto;
        padding: 10px;
        margin: 0;

        + .woocommerce-button {
          margin-top: 10px;
        }
      }

      th,
      td {
        padding: 20px 10px;
        border-bottom: 1px solid $color-border;
      }

      th {
        font-weight: $bold;
      }

      thead,
      tfoot {
        th,
        td {
          background: $white-gray;
          padding: 10px;
        }
      }
    }

    &-table {
      @include is-mobile {
        margin-bottom: 20px;
      }
    }

    &-orders-table {
      @include is-mobile {
        display: block;
      }

      #{$this}-button {
        @include is-mobile {
          width: 100%;
          max-width: 100%;
        }
      }

      &__cell-order-actions {
        &::before {
          @include is-mobile {
            display: none;
          }
        }
      }

      tbody,
      tr {
        @include is-mobile {
          display: block;
        }
      }

      tr {
        @include is-mobile {
          background: $white-gray;
          padding: 10px;
          margin: 0 0 20px;
        }
      }

      th,
      td {
        @include is-mobile {
          display: block;
          padding: 10px;
          border-bottom: 0;
        }
      }

      td {
        @include is-mobile {
          text-align: right;
        }

        &::before {
          @include is-mobile {
            @include fz(12);
            font-weight: $bold;
            content: attr(data-title);
            float: left;
            padding-top: 3px;
            text-transform: uppercase;
          }
        }
      }

      thead {
        @include is-mobile {
          display: none;
        }
      }
    }
  }
}
