@charset "UTF-8";

/*----------------------------------------
woocommerce-error
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce-shipping-methods,
  .wc_payment_method {
    margin: 0 0 20px;

    input {
      &[type="checkbox"],
      &[type="radio"] {
        + span,
        + label {
          &::before {
            content: "";
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
          }

          &::after {
            width: 4px;
            height: 9px;
            top: calc(50% - 1px);
            left: 8px;
            transform-origin: center center;
            transform: translateY(-50%) rotate(45deg);
          }
        }
      }
    }

    p:not([class]) {
      @include fz(14);
      margin: 10px 0 0;
    }
  }
}
