@charset "UTF-8";

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce {
  $this: &;

  .products {
    &.up-sells {
      width: calc(50% - 60px);
      margin: 40px 0 0 30px;
      padding: 30px;
      background: $white-gray;

      @include is-tablet {
        width: 50%;
        margin: 20px 0 40px;
        padding: 30px 30px 0;
      }

      @include is-mobile {
        width: 100%;
        padding: 20px 20px 0;
      }

      h2:not([class]) {
        @include fz(18);
        padding: 0;
        border-bottom: 0;
      }

      .products {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        .product {
          width: 50%;
          padding: 0 10px;
          margin: 0 0 40px;
        }
      }
    }
  }
}
