@charset "UTF-8";

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce {
  $this: &;

  .cross-sells {
    padding: 20px 20px 0;
    margin: 0 0 30px;
    background: $white-gray;

    h2:not([class]) {
      @include fz(16);
      padding: 0;
      border-bottom: 0;
    }

    .products {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      .product {
        width: 50%;
        padding: 0 10px;
        margin: 0 0 40px;
      }
    }
  }
}
