@charset "UTF-8";

/*----------------------------------------
woocommerce-MyAccount
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-MyAccount {
      &-navigation {
        width: 330px;
        padding: 0 40px 0 0;

        @include is-tablet {
          background: $white-gray;
          padding: 20px 40px;
          width: 100%;
          order: 2;
        }

        @include is-mobile {
          width: 100%;
          padding: 20px 20px 40px;
        }

        &-link {
          text-align: left;
          border-bottom: 1px solid $primary-font2;
          margin: 0;

          @include is-mobile {
            margin: 0;
          }

          a {
            @include fz(16);
            font-weight: $bold;
            // color: $gold;
            display: block;
            padding: 18px 0;
            text-decoration: none;
            transition: color .2s ease;

            @include is-mobile {
              @include fz(16);
              padding: 18px;
            }
          }
        }
      }

      &-content {
        width: calc(100% - 330px);
        padding: 0 0 0 40px;

        @include is-tablet {
          width: 100%;
          order: 1;
          padding: 0 0 30px;
        }

        .u-column1 {
          padding-left: 0;
        }

        .u-column2 {
          padding-right: 0;
        }
      }
    }
  }
}
