@charset "UTF-8";

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce {
  $this: &;

  &.is-product {
    @include is-tablet {
      padding: 40px 20px 0;
    }

    @include is-mobile {
      padding: 1px 3.75% 0;
    }
  }

  &.is-difference {
    .product {
      .price {
        @include fz(22);
        background: $white-gray;
        padding: 15px 20px;
      }
    }
  }

  &-product {
    .woocommerce-notices-wrapper {
      padding: 0 40px;
      margin: 0 0 40px;
    }
  }

  .content-area {
    width: 100%;

    @include is-mobile {
      margin-top: 16px;
    }
  }

  .product {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .onsale {
      display: none;
    }

    .summary {
      width: percentage(640 / 1600);
      // padding: 0 0 0 80px;
      position: sticky;
      top: 0;

      @include is-tablet {
        position: relative;
        padding: 0 0 0 30px;
      }

      @include is-mobile {
        width: 100%;
        padding: 0;
        margin: 0 0 30px;
      }
    }

    .quantity {
      float: left;

      input {
        @include fz(16);
        width: 100px;
        text-align: center;
        padding: 14px 0 14px 16px;
        color: $primary-font;
        border-color: $primary-font2;

        @include is-mobile {
          @include fz(14);
          width: 80px;
          padding: 10px 0;
        }
      }

      &:not(.hidden) + button {
        width: calc(100% - 100px);

        @include is-mobile {
          width: calc(100% - 80px);
          padding: 11px 0;
        }
      }
    }

    &_meta {
      padding: 30px 0 0;
      color: $primary-font;

      > span {
        display: block;
        margin: 0 0 10px;
      }
    }

    &_title {
      @include fz(18);
      margin: 0 0 10px;
      width: calc(100% - 60px);

      @include is-mobile {
        @include fz(16);
      }
    }

    .woocommerce-variation-price {
      .price {
        @include fz(24);
        background: none;
        padding: 0;

        @include is-mobile {
          @include fz(22);
        }
      }
    }

    .price {
      @include fz(32);
      line-height: 1.2;
      margin: 0 0 20px;

      small {
        @include fz(16);
        margin: 0 0 0 5px;
      }

      del {
        @include fz(32);
        margin: 0 10px 10px 0;

        @include is-mobile {
          @include fz(28);
        }
      }

      ins {
        @include fz(32);
        text-decoration: none;

        @include is-mobile {
          @include fz(28);
        }

        // scss-lint:disable SelectorDepth
        .woocommerce-Price {
          // scss-lint:disable SelectorDepth
          &-amount {
            @include ls(2);
            color: $red;
          }

          // scss-lint:disable SelectorDepth
          &-currencySymbol {
            font-size: 80%;
            margin: 0 2px;
          }
        }
      }

      .woocommerce-Price {
        &-amount {
          color: $primary-font;
        }

        &-currencySymbol {
          font-size: 80%;
          margin: 0 2px;
        }
      }
    }

    .variations_form {
      th,
      td {
        padding: 10px 0;
      }

      select {
        width: 100%;
        padding: 10px 20px;
        color: $primary-light;
        border-color: $primary-font2;
      }

      .label {
        width: 100px;
        font-weight: normal;
      }

      .variations {
        width: 100%;
      }

      .reset_variations {
        @include fz(12);
        margin: 10px 0 0;
        padding: 6px 20px;
        background: $white-gray;
        border: 1px solid $color-border;
        // scss-lint:disable ImportantRule
        display: none !important;
      }
    }
  }

  &-product-gallery {
    // width: 50%;
    width: percentage(960 / 1600);

    padding: 0 70px;
    position: relative;

    @include is-tablet {
      padding: 0;
    }

    @include is-mobile {
      width: 100%;
      padding: 0;
      margin: 0 0 20px;
    }

    .c-favorite-btn {
      width: 68px;
      height: 68px;
      top: 30px;
      right: 60px;

      @include is-tablet {
        width: 46px;
        height: 46px;
        top: 10px;
        right: 20px;
      }

      @include is-mobile {
        right: 10px;
      }

      &::before {
        border-width: 34px;

        @include is-mobile {
          border-width: 23px;
        }
      }

      &::after {
        @include fz(30);

        @include is-tablet {
          @include fz(24);
        }
      }
    }

    &__slide {
      margin: 0 0 20px;
      position: relative;

      @include is-mobile {
        padding: 0;
        margin: 0 0 10px;
      }

      .swiper-slide {
        padding: 120% 0 0;
        position: relative;

        div {
          background: no-repeat center $white-gray;
          background-size: contain;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

      &-controls {
        position: absolute;
        top: 50%;
        right: -50px;
        left: -50px;
        pointer-events: none;
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);
        z-index: 10;

        @include is-tablet {
          right: 0;
          left: 0;
        }

        &-next,
        &-prev {
          width: 50px;
          height: 50px;
          display: block;
          background: none;
          border: 0;
          outline: none;
          cursor: pointer;
          pointer-events: auto;

          @include is-tablet {
            background: rgba($white, .5);
            width: 40px;
            height: 80px;
          }

          &::after {
            content: "";
            display: inline-block;
            width: 30px;
            height: 30px;
            border: solid $color-border-gray;
            border-width: 2px 2px 0 0;
            transform: rotate(45deg);

            @include is-tablet {
              width: 18px;
              height: 18px;
              border-width: 1px 1px 0 0;
            }
          }
        }

        &-next {
          @include is-tablet {
            padding: 0 10px 0 0;
          }
        }

        &-prev {
          @include is-tablet {
            padding: 0 0 0 10px;
          }

          &::after {
            transform: rotate(-135deg);
          }
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-wrap: wrap;

      @include is-mobile {
        padding: 0;
      }
    }

    &__image {
      font-size: 0;
      width: 20%;
      padding: 10px;
      cursor: pointer;
      margin-left: -10px;

      @include is-mobile {
        width: 25%;
      }

      &.is-active {
        a {
          box-shadow: 0 0 0 3px $red;
        }
      }

      a {
        display: block;
        pointer-events: none;
      }

      img {
        width: 100%;
      }
    }
  }

  &-tabs {
    display: none;
  }

  &-variation {
    &-add-to-cart {
      margin: 20px 0 0;
    }
  }

  .single_variation_wrap {
    padding: 10px 0 0;
  }

  .stock {
    font-weight: $bold;
    margin: 5px 0 0;

    &.out-of-stock {
      color: $red;
    }
  }
}
