@charset "UTF-8";

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-order-delivery-info {
      margin: 0 0 40px;

      &-ttl {
        @include fz(18);
        padding: 0 0 10px;
        border-bottom: 1px solid $color-border;
        font-weight: 900;
        margin: 0 0 20px;
      }

      &-txt {
        margin: 10px 0 0;
      }

      &-link {
        color: $red;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
