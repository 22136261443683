@charset "UTF-8";

/*----------------------------------------
woocommerce-form
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-form {
      &-coupon {
        max-width: 480px;
        background: $white-gray;
        border: 1px solid $color-border;
        width: 100%;
        padding: 20px;

        @include is-tablet {
          max-width: 100%;
        }

        p {
          @include fz(14);
          max-width: 480px;
          margin: 0 0 5px;

          @include is-tablet {
            max-width: 100%;
          }
        }

        input {
          background: $white;
        }

        button {
          font-family: $font;
          font-weight: $bold;
          color: $white;
          width: 100%;
          background: $red;
          padding: 10px 0;
          border: 0;
          box-shadow: none;
          transition: background .5s $ease;
          text-align: center;
          margin: 20px auto 0;
          display: block;
          cursor: pointer;

          @include is-mobile {
            margin: 20px auto 0;
          }

          &:hover {
            background: $red-black;
          }

          &:disabled {
            opacity: .5;
            cursor: auto;

            &:hover {
              background: $red;
            }
          }
        }

        &-toggle {
          width: 100%;

          @include is-tablet {
            padding: 20px 0 0;
          }

          .woocommerce-info {
            max-width: 480px;
            padding: 10px;
            margin: 0;

            @include is-tablet {
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
