@charset "UTF-8";

/*----------------------------------------
cart-collaterals
----------------------------------------*/
.woocommerce {
  // scss-lint:disable IdSelector
  #order_review:not([class]) {
    display: flex;

    @include is-tablet {
      display: block;
    }

    @include is-mobile {
      padding: 0 $side-padding;
    }

    > * {
      width: 50%;
      margin: 0 0 20px;

      @include is-tablet {
        width: 100%;
      }
    }

    th,
    td {
      padding: 20px 10px;
      border-bottom: 1px solid $color-border;
    }

    th {
      font-weight: $bold;
    }

    thead,
    tfoot {
      th,
      td {
        background: $white-gray;
        padding: 10px;
      }
    }

    .tax_label,
    .includes_tax {
      @include fz(14);
      display: inline-block;
    }

    // scss-lint:disable IdSelector
    #payment {
      padding: 0 0 0 40px;

      @include is-tablet {
        padding: 20px 0;
      }
    }

    // scss-lint:disable IdSelector
    .payment_box {
      padding: 10px 0 0;
      // scss-lint:disable ImportantRule
      display: block !important;

      p:not([class]) {
        margin: 0;
      }
    }
  }
}
